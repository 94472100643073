import Grid from "@mui/material/Grid";
import styled from "styled-components";

export default function Mission() {
  return (
    <MissionPart>
      <div className="main-p py-5 text-start text-white our-mission-container">
        <Grid
          container
          alignItems="center"
          className="h-100 py-2"
          style={{ marginBottom: "40px" }}
        >
          <Grid item xs={12} lg={2}>
            <h5>Our Mission</h5>
          </Grid>
          <Grid item xs={12} lg={10}>
            <p className="font-sm" style={{ margin: 0 }}>
              Anmol Network is on a mission to ease the onboarding of external
              users in the Blockchain space. Establishing it as the portal for
              Web2.0 to Web3.0 migrations for individuals, and businesses alike.
            </p>
          </Grid>
        </Grid>
        <Grid container alignItems="center" className="h-100 py-2">
          <Grid item xs={12} lg={2}>
            <h5>Our Vision</h5>
          </Grid>
          <Grid item xs={12} lg={10}>
            <p className="font-sm" style={{ margin: 0 }}>
              To onboard and facilitate One Billion (1,000 Million), new users,
              to the Blockchain space - while making Anmol a major player in
              on-chain and off-chain markets.
            </p>
          </Grid>
        </Grid>
      </div>
    </MissionPart>
  );
}

const MissionPart = styled.div`
  background: #3c1466;

  p {
    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }
`;
