import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function MissionStatement() {
  return (
    <MissionSec>
      <Box flexGrow={1} className="text-white text-start">
        <div>
          <h5>Our Mission Statement</h5>
          <p>
            At Anmol, our mission is to make Web3 technologies accessible,
            whilst promoting decentralization and blockchain tech as the norm
            within this industry. We are building a multi-chain Web3 toolkit,
            designed to remove the barriers of entry into the market by giving
            businesses, corporations, influencers, content creators, artists,
            and average users the ability to transition to the new wave of
            online activity, all without any programming needs.
          </p>
          <p>
            We see Anmol as the missing piece in this industry. The Web3 market
            is currently disjointed, off-putting to newcomers, and deeply
            inaccessible. Anmol is aiming to fix this by crafting an
            interoperable and beginner-friendly infrastructure for onboarding
            and transitioning organizations into Web3 technologies. We believe
            this is necessary for widespread adoption and overall maturity, as
            an industry like this cannot grow and become stable without a global
            network of varied and avid users.
          </p>
          <p>
            We are driven by two core values: bringing Web3 to the masses, and
            reducing the complexity of the market. These are two areas that
            commonly hold back the new wave of the internet, as businesses often
            feel too daunted to build their infrastructure on this technology.
            We aim to fix that by providing tools that make Web3 easy to access,
            easy to use, and (above all) easy to understand. If Web3 is to
            become the norm, then it needs to become a welcome, and approachable
            space. This is why we are working so hard to ensure our toolkit and
            blockchain is functional and simple for all users, regardless of
            plans, size, or technical background.
          </p>
        </div>
        <Link to="/careers">
          <ButtonBorder>
            <button>Join Our Team</button>
          </ButtonBorder>
        </Link>
      </Box>
    </MissionSec>
  );
}

const MissionSec = styled.div`
  padding: 0 8.5vw;
  padding-bottom: 93px;
  h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 20px;
    color: #ffffff;
    text-align: center;
    width: 100%;
  }

  p {
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    margin-bottom: 16px;
    text-align: left;
  }
`;

const ButtonBorder = styled.div`
  background: linear-gradient(#fc1ba7 100%, #000000 0%, #40affd 100%);
  width: 159px;
  height: 46px;
  padding: 1px;
  border-radius: 23px;
  overflow: hidden;
  margin: 30px auto 0;

  & > button {
    background: linear-gradient(90deg, #9230af 4.09%, #181058 94.03%);
    border-radius: 23px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: none;
    outline: none;
    font-family: "Hind";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;

    &:hover {
      background: linear-gradient(90.21deg, #ff4ff5 2.06%, #9230af 81.13%);
    }
  }
`;
