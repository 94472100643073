import "./App.css";
import React, { useEffect } from "react";
import { Navbar } from "./main";
import { MainPage, Teams } from "./pages";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { KnowMore } from "./pages";
import { Scrollbars } from "react-custom-scrollbars";
import AboutUs from "./pages/AboutUs";
import Careers from "./pages/Careers";

function App() {
  useEffect(() => {
    setTimeout(() => {
      const el = document.querySelector(".loading");
      if (el) {
        el.remove();
      }
    }, 1500);
  }, []);
  return (
    <Router>
      <Scrollbars style={{ height: "100vh" }}>
        <div className="App">
          <Navbar />
          <Routes>
            <Route exact path="/" element={<MainPage />} />
            <Route exact path="team/" element={<AboutUs />} />
            <Route exact path="careers/" element={<Careers />} />
            <Route exact path="know-more/" element={<KnowMore />} />
          </Routes>
        </div>
      </Scrollbars>
    </Router>
  );
}

export default App;
