import styled from "styled-components";
import data from "../data/data.json";
import useWidth from "../hooks/useWidth";
import OwlCarousel from "react-owl-carousel";

export default function Partners() {
  const partnerData = data.partners;
  const partnersElements = partnerData.map((item) => (
    <div items className="owl-line-item">
      <Partner key={item.name} item={item} />
    </div>
  ));
  const partnersElements1 = partnersElements.slice(0, 7);
  const partnersElements2 = partnersElements.slice(7);

  //mobile and tablet
  const partnersElements3 = partnersElements.slice(0, 4);
  const partnersElements4 = partnersElements.slice(4, 8);
  const partnersElements5 = partnersElements.slice(8, 12);
  const partnersElements6 = partnersElements.slice(12);

  const width = useWidth();

  const options = {
    items: 8,
    loop: true,
    nav: false,
    autoplay: true,
    autoplayHoverPause: true,
    dots: false,
    autoplaySpeed: 4000,
    responsive: {
      0: {
        items: 4,
      },
      1024: {
        items: 6,
      },
      580: {
        items: 4,
      },
      1440: {
        items: 6,
      },
    },
  };

  return (
    <PartnersSec>
      <h2>Partners & backers </h2>
      <PartnersBox>
        <>
          <OwlCarousel className="owl-theme" {...options}>
            {partnersElements1}
          </OwlCarousel>
          <OwlCarousel className="owl-theme" {...options}>
            {partnersElements2}
          </OwlCarousel>
        </>
      </PartnersBox>
    </PartnersSec>
  );
}

const PartnersSec = styled.div`
  margin-top: 152px;
  padding: 0 163px;

  @media (max-width: 1200px) {
    padding: 0 100px;
  }

  @media (max-width: 480px) {
    padding: 0 20px;
  }

  & > h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 110%;
    margin-bottom: 87px;
    text-transform: capitalize;
    background: linear-gradient(
      90deg,
      #ffffff 0%,
      #ffffff 50.09%,
      #ffffff 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @media (max-width: 480px) {
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
`;

const PartnersBox = styled.div`
  // margin: 0 -2vw;

  @media (max-width: 1200px) {
    margin: 0 -10px;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 65px;

    @media (max-width: 1200px) {
      justify-content: center;
      &:last-child {
        margin: auto;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Partner = ({ item }) => {
  return (
    <PartnerBox>
      <a href={item.link} target={"_blank"}>
        <PartnerImage>
          <img src={item.image} alt={item.name} />
        </PartnerImage>
      </a>
    </PartnerBox>
  );
};

const PartnerBox = styled.div`
  width: 8vw;
  height: 7vw;
  background: Linear-Gradient(#fe18a6, #5761d7, #448bdd);
  margin: 0 2vw;
  box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  padding: 1.5px;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    margin: 0 10px;
    width: 12vw;
    height: 11vw;
  }

  @media (max-width: 480px) {
    width: 20vw;
    height: 18vw;
  }
`;

const PartnerImage = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #110e4e 0%, #121053 46.06%, #491d7d 100%);
  border-radius: 8px;
  display: flex;

  & > img {
    margin: auto;
    max-width: 80%;
    max-height: 80%;
  }
`;
