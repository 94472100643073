import styled from "styled-components";
import data from "../data/data.json";
import useWidth from "../hooks/useWidth";
import OwlCarousel from "react-owl-carousel";

export default function NewsPart() {
  const newsData = data.news;
  const width = useWidth();

  const newsElement = newsData.map((item) => (
    <div items className="owl-line-item">
      <a href={item.link} target={"_blank"}>
        <img key={item.name} src={item.image} alt={item.name} />
      </a>
    </div>
  ));

  const newsElement1 = newsElement.slice(0, 5);
  const newsElement2 = newsElement.slice(5);

  const options = {
    items: 8,
    loop: true,
    nav: true,
    autoplay: true,
    autoplayHoverPause: true,
    dots: false,
    autoplaySpeed: 4000,
    responsive: {
      0: {
        items: 2,
      },
      1024: {
        items: 4,
      },
      580: {
        items: 4,
      },
      1440: {
        items: 4,
      },
    },
  };
  return (
    <NewsPartSection>
      <h2>Anmol Network in The News </h2>
      <NewsBox>
        <OwlCarousel className="owl-theme" {...options}>
          {newsElement1}
        </OwlCarousel>
        <OwlCarousel className="owl-theme" {...options}>
          {newsElement2}
        </OwlCarousel>
      </NewsBox>
    </NewsPartSection>
  );
}

const NewsPartSection = styled.div`
  margin-top: 159px;
  margin-bottom: 174px;
  padding: 0 157px;

  @media (max-width: 1200px) {
    padding: 0 50px;
  }

  @media (max-width: 480px) {
    padding: 0 20px;
  }
  & > h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 110%;
    text-transform: capitalize;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 115px;

    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
`;

const NewsBox = styled.div`
  margin: 0 -32px;
  & > div {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin: 0 0 115px;

    @media (max-width: 1200px) {
      justify-content: center;
      padding: 0 20px;
    }

    @media (max-width: 480px) {
      margin-bottom: 40px;
    }

    &:last-child {
      margin-bottom: 0;
      align-items: center;

      .owl-stage {
        display: flex;
        align-items: center;
      }
    }

    img {
      width: auto;
      max-width: 14vw;
      height: auto;
      margin: 0 32px;
      @media (max-width: 480px) {
        width: 20vw;
        max-width: unset;
        height: 10vw;
        margin: 0 10px;
        object-fit: contain;
      }
    }
  }
`;
