import React, { useState, useCallback, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { PrivacyPolicy, TermsAndConditions, JobPopup } from "./popup";
import { makeStyles } from "@material-ui/core/styles";
import Business from "../images/slide/Business Insider.png";
import Daily from "../images/slide/Daily Times.png";
import pp from "../images/slide/pp_site_logo 1.png";
import StartUp from "../images/slide/StartUp Pakistan 1.png";
import TechDad from "../images/slide/TechDad 1.png";
import TheNation from "../images/slide/The Nation 1.png";
import Yahoo from "../images/slide/Yahoo News.png";
import video from "../Assets/video/vid.mp4";
import solana from "../Assets/networksIcons/Solana.svg";
import avalanche from "../Assets/networksIcons/Avalanche.svg";
import binance from "../Assets/networksIcons/Binance.svg";
import eth from "../Assets/networksIcons/ETH.svg";
import flow from "../Assets/networksIcons/Flow.svg";
import kusama from "../Assets/networksIcons/Kusama.svg";
import nervos from "../Assets/networksIcons/Nervos.svg";
import polkadot from "../Assets/networksIcons/Polkadot.svg";
import polygon from "../Assets/networksIcons/Polygon.svg";
import tezos from "../Assets/networksIcons/Tezos.svg";
import theta from "../Assets/networksIcons/Theta.svg";
import wax from "../Assets/networksIcons/Wax.svg";
import arrowLeft from "../Assets/arrowLeft.svg";
import slideBtn from "../Assets/BrowserIcons/slideBtn.svg";
import cross from "../Assets/BrowserIcons/Cross.svg";
import browserArrowLeft from "../Assets/BrowserIcons/browserArrowLeft.svg";
import anmolLogo from "../Assets/BrowserIcons/Anmol.svg";
import more from "../Assets/BrowserIcons/More.svg";
import lock from "../Assets/BrowserIcons/Lock.svg";
import arrowDown from "../Assets/BrowserIcons/Arrow.svg";
import { ReactComponent as FaqArrow } from "../Assets/faqArrow.svg";
import { Link, NavLink } from "react-router-dom";
// import moulds from "../Assets/BrowserIcons/Moulds.png";
import tree from "../Assets/SwiperImges/Tree.png";
import literacy from "../Assets/SwiperImges/Literacy.png";
import { ReactComponent as SliderArrowLeft } from "../Assets/SwiperImges/sliderArrowLeft.svg";
import { ReactComponent as SliderArrowRight } from "../Assets/SwiperImges/sliderArrowRight.svg";
import { Pagination } from "swiper";
import "swiper/less/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import { Member } from "./members";
import data from "../jobs.json";
import { browserInfo, faqInfo } from "../constants";
import "./main.css";
import videoPoster from "../Assets/video/videoPoster.png";
import { ReactComponent as JobDot } from "../Assets/jobDot.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export function FirstSection() {
  return (
    <div className="main-bg">
      <div className="main-page py-5 main-p d-flex flex-column justify-content-lg-start ">
        {/*<Box className="h-100 ">*/}
        <Grid container spacing={1} className="h-100 pt-5">
          <Grid items xs={12} lg={6} md className="column">
            <div className="d-flex flex-column justify-content-center h-100 text-white">
              <div className="text-start mb-4">
                <h2 className=" mob-center-text">
                  The Ultimate No-Code Builder for Web3
                </h2>
                <div className="main-text-div mob-center-text">
                  <p>
                    Anmol Network is “The Ultimate No-Code Builder For Web3”. We
                    offer tools for NFTs, Metaverse, GameFi, and DeFi, as well
                    as Social Tools that bring the connectivity and engagements
                    of social networks to the Blockchain space.
                  </p>
                  <p>
                    Whether you want to create a new audited token with
                    customized vesting, a DAO, a generative art NFT project of
                    thousands, or even millions of NFTS, this and much more can
                    happen in minutes using Anmol’s DApp Dashboard
                    <i> with no coding skills required</i>, just like a
                    WordPress of Canva - but for Web3.
                  </p>
                </div>
              </div>
            </div>
          </Grid>

          <Grid items xs={12} md lg={6} className="column">
            <div className="mt-5">
              <img
                src={require("../images/Multichain.png")}
                className="w-100"
              />
            </div>
          </Grid>

          <Grid items xs={12} lg={6}>
            <div className="d-flex flex-column cat-button-div">
              <div className="text-start" style={{ zIndex: 9 }}>
                <img
                  src={require("../images/Cat Button 2.png")}
                  className="mx-4"
                />
              </div>
              <div className="text-start nft-btn-holder">
                <a
                  href="http://freenft.anmol.network/"
                  variant="primary"
                  className="nft-btn px-3 py-2"
                  target="_blank"
                >
                  <strong>Claim Your Free NFT</strong>
                </a>
              </div>
              <div className="text-start">
                <img
                  src={require("../images/Cat Button 2-feet.png")}
                  className="cat-feet"
                />
              </div>
            </div>
          </Grid>
        </Grid>

        {/*</Box>*/}
      </div>
      <Partners />
    </div>
  );
}

export function Partners() {
  return (
    <Box sx={{ flexGrow: 1 }} className="h-100 main-p partners-block">
      <div className="text-center first-block">
        <h4 className="purp">
          <strong>Our Partners</strong>
        </h4>
      </div>
      <div className="w-100 px-5">
        <Grid
          container
          spacing={1}
          style={{ paddingTop: "20px", paddingBottom: "40px" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid items xs={12} md lg={3} className="my-4 px-2">
            <a
              href="https://medium.com/anmolnetwork/anmol-x-darwinia-recap-from-29-of-september-2021-523b96221805"
              target="_blank"
            >
              <img
                src={require("../images/Clip-6.png")}
                className="w-100 partners mx-2"
              />
            </a>
          </Grid>
          <Grid items xs={12} md lg={3} className="my-4 px-2">
            <a
              href="https://medium.com/anmolnetwork/anmol-network-is-integrating-with-polygon-252d2a7c4089"
              target="_blank"
            >
              <img
                src={require("../images/Polygon.png")}
                className="w-100 partners mx-2"
              />
            </a>
          </Grid>
          <Grid items xs={12} md lg={3} className="my-4 px-2">
            <a
              href="https://medium.com/anmolnetwork/crust-network-and-anmol-collaborate-for-decentralized-storage-ae810290f977"
              target="_blank"
            >
              <img
                src={require("../images/rust.png")}
                className="w-100 partners imgSmall mx-2 rust"
              />
            </a>
          </Grid>
          <Grid items xs={12} md lg={3} className="my-4 px-2">
            <a
              href="https://medium.com/anmolnetwork/anmol-integrates-with-cere-e27562e56bda"
              target="_blank"
            >
              <img
                src={require("../images/cere.png")}
                className="w-100 partners imgSmall mx-2 rust"
              />
            </a>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export function Intro() {
  const [isVideoPlay, setIsVideoPlay] = useState(false);

  const setVideoStatus = () => {
    const video = document.getElementById("video");

    video.addEventListener(
      "click",
      function () {
        if (!isVideoPlay) {
          video.play();
          setIsVideoPlay(true);
        } else {
          video.pause();
          setIsVideoPlay(false);
        }
      },
      false
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }} className="h-100 media-box media-box2">
      <Grid container spacing={1} className="media-wrapper">
        <Grid
          items
          xs={12}
          md
          lg={5}
          className="intro-column"
          style={{ flexDirection: "column" }}
        >
          {/*<div className='p-5'>*/}
          <div className="p-5 text-start mob-center-text">
            <h4>
              <strong>Introduction to Moulds</strong>
            </h4>
            <p className="font-sm">
              Watch the video and learn everything you need to know about
              moulds, from what they are to how to use them on Anmol.
            </p>
            <div className="block-btns">
              <a href="http://earlyaccess.anmol.network/" target="_blank">
                <button className="pill-btn">Get Early Access</button>
              </a>
              <a href="/know-more">
                <button className="outline-btn">Know more</button>
              </a>
            </div>
            {/*</div>*/}
          </div>
        </Grid>
        <Grid items xs={12} md lg={7} className="column">
          <div className="w-100 h-100 bg-dark video">
            <video
              preload="auto"
              controls
              onClick={() => setVideoStatus()}
              poster={videoPoster}
            >
              <source type="video/mp4" src={video} />
            </video>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export function Desc() {
  return (
    <Box
      sx={{ flexGrow: 1 }}
      className="h-100 bg-blue main-p"
      style={{ paddingTop: "80px", marginTop: "-1px" }}
    >
      <Grid container spacing={1} className="">
        <Grid
          items
          xs={12}
          lg={5}
          md
          className="column d-flex flex-column justify-content-center align-items-center"
        >
          <div className="text-start">
            <h4 className="text-white">
              <strong>Anmol</strong>
            </h4>
            <h6 className="purp mb-3">A Brand New Blockchain Ecosystem</h6>
            <p className="text-white desc-text">
              Anmol is building a new blockchain for the new wave of internet
              activity, perfect for transferring NFTs, tokens, and other digital
              assets. Alongside this, we are releasing our own new blockchain
              with a toolkit, allowing businesses, virtual communities, and
              individuals to rapidly utilize complex or gamified blockchain
              functionalities with nominal overhead costs.
            </p>
          </div>
        </Grid>
        <Grid items xs={12} md lg={7} className="text-end">
          <img
            className="desc-image"
            src={require("../images/Own chain_section.png")}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export function Backers2() {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 500,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const options = {
    items: 7,
    loop: true,
    nav: true,
    autoplay: true,
    autoplayHoverPause: true,
    dots: false,
    autoplaySpeed: 3000,
    responsive: {
      0: {
        items: 1,
      },
      580: {
        items: 3,
      },
      1024: {
        items: 4,
      },
      1440: {
        items: 5,
      },
    },
  };
  return (
    <div
      className="multi-chain-mob bg-blue main-p w-100 h-100 business"
      style={{ marginTop: "-1px" }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center py-4 w-100">
        <div className="multi-chain">
          <h4 className="purp my-4">
            <strong>Anmol Network</strong>
            <span className="text-white"> in the Media</span>
          </h4>
        </div>
      </div>

      <Box sx={{ flexGrow: 1 }} className="h-100 py-4 slickSlider">
        <OwlCarousel className="owl-theme" {...options}>
          <div items className="owl-line-item">
            <a
              href="https://dailytimes.com.pk/945434/anmol-network-secures-1-million-in-seed-funding/"
              target="_blank"
            >
              <img src={Daily} className="chan-img2" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a
              href="https://markets.businessinsider.com/news/stocks/anmol-network-secure-1-million-in-seed-funding-investor-group-reflects-strong-support-for-mass-adoption-of-web3-technologies-1031415898"
              target="_blank"
            >
              <img src={Business} className="chan-img2" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a
              href="https://finance.yahoo.com/news/anmol-network-secure-1-million-214000514.html"
              target="_blank"
            >
              <img src={Yahoo} className="chan-img2" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a
              href="https://startuppakistan.com.pk/pakistan-based-startup-anmol-network-raises-1m-to-build-a-no-code-access-portal-to-the-metaverse/"
              target="_blank"
            >
              <img src={StartUp} className="chan-img2" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a
              href="https://techdad.pk/they-built-a-tool-that-made-38-million-nfts-in-30-minutes-whats-next-for-anmol-network/"
              target="_blank"
            >
              <img src={TechDad} className="chan-img2" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a
              href="https://nation.com.pk/2022/06/03/pakistan-based-startup-raises-1m-to-make-blockchain-more-accessible/"
              target="_blank"
            >
              <img src={TheNation} className="chan-img2" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a
              href="https://propakistani.pk/2022/06/02/meet-pakistans-top-innovator-building-the-future-of-the-internet/"
              target="_blank"
            >
              <img src={pp} className="chan-img2" />
            </a>
          </div>
        </OwlCarousel>
      </Box>
    </div>
  );
}

export function Backers() {
  return (
    <Box sx={{ flexGrow: 1 }} className="h-100 bg-blue py-5 main-p">
      <div className="text-center my-5">
        <h4 className="purp">
          <strong>Our Backers</strong>
        </h4>
      </div>
      <Grid container spacing={1} className="px-5 Backers">
        <Grid items xs={4} md lg={3} className="column my-3 ourBackers">
          <a href="https://au21.capital/" target="_blank">
            <img src={require("../images/au21-capital (1).png")} />
          </a>
        </Grid>
        <Grid items xs={4} md lg={3} className="column my-3 ourBackers">
          <a href="https://www.lotuscapital.me/" target="_blank">
            <img src={require("../images/Lotus Capital.png")} />
          </a>
        </Grid>
        <Grid items xs={4} md lg={3} className="column my-3 ourBackers">
          <a href="https://www.mexc.com/" target="_blank">
            <img src={require("../images/Layer 1 2.png")} />
          </a>
        </Grid>
      </Grid>
      <Grid container spacing={1} className="px-5 Backers">
        <Grid items xs={4} md lg={3} className="column my-3 ourBackers">
          <a href="http://www.ybb.io/" target="_blank">
            <img
              src={require("../images/YBB Foundation.png")}
              className="imgSmall"
            />
          </a>
        </Grid>
        <Grid items xs={4} md lg={3} className="column my-3 ourBackers">
          <div className="text-center my-3 text-white ">
            <a
              href="https://www.linkedin.com/in/rob-hanneman-991a33156/"
              target="_blank"
            >
              <h4>Rob Hanneman</h4>
            </a>
          </div>
        </Grid>
        <Grid items xs={4} md lg={3} className="column my-3 ourBackers">
          <a href="https://hgventures.com/" target="_blank">
            <img src={require("../images/Layer 2 1.png")} className="imgBig" />
          </a>
        </Grid>
      </Grid>
      <Grid container spacing={1} className="Backers">
        <Grid items xs={12} md lg={12} className="column ourBackers">
          <div className="text-center my-3 text-white ">
            <a href="https://www.linkedin.com/in/djchaudhry" target="_blank">
              <h4>Danish Chaudhry</h4>
            </a>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export function TemplateTool() {
  return (
    <Box sx={{ flexGrow: 1 }} className="h-100 bg-blue py-5 main-p">
      <div classname="m-5">
        <div className="px-5">
          <img
            src={require("../images/Tools (NFT Tools).png")}
            className="w-100"
          />
        </div>
      </div>
    </Box>
  );
}

export function MultiChain() {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 10,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 500,
    cssEase: "linear",
    arrows: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const options = {
    items: 10,
    loop: true,
    nav: true,
    autoplay: true,
    autoplayHoverPause: true,
    dots: false,
    autoplaySpeed: 3000,
    responsive: {
      0: {
        items: 3,
      },
      1024: {
        items: 6,
      },
      580: {
        items: 5,
      },
      1440: {
        items: 8,
      },
    },
  };

  return (
    <div
      className="multi-chain-mob bg-blue py-5 main-p w-100 h-100"
      style={{ marginTop: "-1px" }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center py-5 w-100">
        <div className="multi-chain">
          <h4 className="purp my-4">
            <strong>Multi-Chain</strong>
          </h4>
          <p className="text-white mb-5 font-sm">
            Our toolkit and blockchain is a fantastic companion to any
            ecosystem, network, or project. We are eagerly looking to build
            bridges with everybody in this industry, helping it to become one
            big community.
          </p>
          <p className="text-white font-sm">
            Anmol can connect to Ethereum-based chains such as Polygon and
            Avalanche, Substrate-based chains such as Polkadot and Darwinia, and
            even more niche chains like Flow, Tezos, Theta, and Wax. We are at
            the bleeding edge of blockchain innovation, as interoperability and
            collective unity is the future of this industry.
          </p>
        </div>
      </div>

      <Box sx={{ flexGrow: 1 }} className="h-100 py-5 slickSlider">
        <OwlCarousel className="owl-theme" {...options}>
          <div items className="owl-line-item">
            <a href="https://www.thetatoken.org/" target="_blank">
              <img src={theta} className="w-100 chan-img" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a href="https://www.solana.com/" target="_blank">
              <img src={solana} className="w-100 chan-img" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a href="https://kusama.network/" target="_blank">
              <img src={kusama} className="w-100 chan-img" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a href="https://www.avax.network/" target="_blank">
              <img src={avalanche} className="w-100 chan-img" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a href="https://polkadot.network/" target="_blank">
              <img src={polkadot} className="w-100 chan-img" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a href="https://ethereum.org/en/" target="_blank">
              <img src={eth} className="w-100 chan-img" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a href="https://binance.org/" target="_blank">
              <img src={binance} className="w-100 chan-img" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a href="https://polygon.technology/" target="_blank">
              <img src={polygon} className="w-100 chan-img" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a href="https://on.wax.io/wax-io/" target="_blank">
              <img src={wax} className="w-100 chan-img" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a href="https://tezos.com/" target="_blank">
              <img src={tezos} className="w-100 chan-img" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a href="https://www.nervos.org/" target="_blank">
              <img src={nervos} className="w-100 chan-img" />
            </a>
          </div>
          <div items className="owl-line-item">
            <a href="https://www.onflow.org/" target="_blank">
              <img src={flow} className="w-100 chan-img" />
            </a>
          </div>
        </OwlCarousel>
      </Box>
    </div>
  );
}

export function Footer() {
  return (
    <Box
      flexGrow={1}
      className="py-5 main-p footer"
      style={{ marginTop: "-1px" }}
    >
      <div className="footer-container">
        <div>
          <div className="text-white">
            <img src={require("../images/Logo.png")} />
            <p
              className="mt-5 footer-text footer-mail"
              style={{ fontSize: "14px" }}
            >
              <a href="mailto:hello@anmol.network">hello@anmol.network</a>
            </p>
          </div>
        </div>

        <div className="footer-links-container">
          <div className="footer-text footer-links text-white py-0">
            <div style={{ marginBottom: "20px" }}>
              <span style={{ fontFamily: "Hind", fontSize: "18px" }}>
                <strong>Important Links</strong>
              </span>
            </div>
            <div className="my-2">
              <a href="https://github.com/anmolnetwork/" target="_blank">
                Github
              </a>
            </div>
            <div className="my-2">
              <a href="/team">About Us</a>
            </div>
            <div className="my-2">
              <a href="/careers">Careers</a>
            </div>
          </div>

          <div className="footer-text text-white social-container">
            <div style={{ marginBottom: "20px" }}>
              <span style={{ fontFamily: "Hind", fontSize: "18px" }}>
                <strong>Social</strong>
              </span>
            </div>
            <div className="footer-icons">
              <div className="footer-icons-container">
                <div>
                  <a href="https://discord.gg/zZHqMwq23X" target="_blank">
                    <img src={require("../images/Discord.png")} />
                  </a>
                </div>
                <div>
                  <a href="https://medium.com/anmolnetwork" target="_blank">
                    <img src={require("../images/Medium.png")} />
                  </a>
                </div>
                <div>
                  <a href="https://twitter.com/AnmolNetwork" target="_blank">
                    <img src={require("../images/Twitter.png")} />
                  </a>
                </div>
              </div>
              <div className="footer-icons-container">
                <div>
                  <a href="https://t.me/anmolnetwork" target="_blank">
                    <img src={require("../images/Telegram.png")} />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.linkedin.com/company/anmolnetwork/"
                    target="_blank"
                  >
                    <img src={require("../images/LinkedIn footer.png")} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-end text-white mt-3 footer-down-text">
        <div className="mr-3">
          <p className="d-inline">© Anmol Network . All rights reserved.</p>
        </div>
        <div className="PPolicy">
          <div className="mx-5">
            <PrivacyPolicy />
          </div>
          <div className="mx-3">
            <a>
              <TermsAndConditions />
            </a>
          </div>
        </div>
      </div>
    </Box>
  );
}

export function MissionStatement() {
  return (
    <Box flexGrow={1} className="text-white text-start mission-container">
      <div>
        <h5 className="mb-4">
          <strong>Our Mission Statement</strong>
        </h5>
        <p className="mb-3">
          At Anmol, our mission is to make Web3 technologies accessible, whilst
          promoting decentralization and blockchain tech as the norm within this
          industry. We are building a multi-chain Web3 toolkit, designed to
          remove the barriers of entry into the market by giving businesses,
          corporations, influencers, content creators, artists, and average
          users the ability to transition to the new wave of online activity,
          all without any programming needs.
        </p>
        <p className="mb-3">
          We see Anmol as the missing piece in this industry. The Web3 market is
          currently disjointed, off-putting to newcomers, and deeply
          inaccessible. Anmol is aiming to fix this by crafting an interoperable
          and beginner-friendly infrastructure for onboarding and transitioning
          organizations into Web3 technologies. We believe this is necessary for
          widespread adoption and overall maturity, as an industry like this
          cannot grow and become stable without a global network of varied and
          avid users.
        </p>
        <p className="mb-3">
          We are driven by two core values: bringing Web3 to the masses, and
          reducing the complexity of the market. These are two areas that
          commonly hold back the new wave of the internet, as businesses often
          feel too daunted to build their infrastructure on this technology. We
          aim to fix that by providing tools that make Web3 easy to access, easy
          to use, and (above all) easy to understand. If Web3 is to become the
          norm, then it needs to become a welcome, and approachable space. This
          is why we are working so hard to ensure our toolkit and blockchain is
          functional and simple for all users, regardless of plans, size, or
          technical background.
        </p>
      </div>
      <Link to="/careers">
        <button className="pill-btn mission-btn">Join Our Team</button>
      </Link>
    </Box>
  );
}

export function FindFuture() {
  const getScrollEffect = () => {
    const el = document.getElementById("positions");
    el.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box flexGrow={1}>
      <div className="second-bg main-p mt-3rem">
        <Grid container className="py-5 text-start career-card text-white">
          <Grid item xs={12} lg={6} md={6}>
            <h6 className="purp">
              <strong>Careers</strong>
            </h6>
            <h5>
              <strong>Find Your Future</strong>
            </h5>
            <p className="font-sm">
              We, at Anmol, are building a team of awesome individuals, experts
              in their domain, ready to tackle challenges that will ultimately
              make Anmol Web3 Toolkit available to masses and allow them to
              learn the art of creating their own NFTs. If you think you see a
              future for yourself in this field then you are at the right place.
            </p>
            <button
              className="pill-btn"
              onClick={() => getScrollEffect()}
              style={{
                padding: "11px 27px",
                filter: "drop-shadow(0px 4px 16px rgba(255, 255, 255, 0.2))",
              }}
            >
              {" "}
              See open positions
            </button>
          </Grid>
        </Grid>
        <div className="py-3">
          <SlideShow />
        </div>
      </div>
    </Box>
  );
}

export function CustomArrow() {
  return null;
}

export function SlideShow() {
  return (
    <div className="photo-wrapper">
      <div>
        <div className="photo-item photo-item-crop-left">
          <img src={require("../images/unsplash_i5Kx0P8A0d4.png")} />
        </div>
        <div className="photo-item">
          <img src={require("../images/unsplash_QckxruozjRg.png")} />
        </div>
        <div className="photo-item">
          <img src={require("../images/unsplash_mpN7xjKQ_Ns.png")} />
        </div>
        <div className="photo-item">
          <img src={require("../images/unsplash_Lks7vei-eAg.png")} />
        </div>
        <div className="photo-item">
          <img src={require("../images/unsplash_eVAULktyLRw.png")} />
        </div>
        <div className="photo-item photo-item-crop-right">
          <img src={require("../images/unsplash_6awfTPLGaCE.png")} />
        </div>
      </div>

      <div style={{ padding: "0 8%" }}>
        <div className="photo-item ">
          <img src={require("../images/unsplash_bzqU01v-G54.png")} />
        </div>
        <div className="photo-item">
          <img src={require("../images/unsplash_-uHVRvDr7pg.png")} />
        </div>
        <div className="photo-item">
          <img src={require("../images/unsplash_rMILC1PIwM0.png")} />
        </div>
        <div className="photo-item">
          <img src={require("../images/unsplash_wD1LRb9OeEo.png")} />
        </div>
        <div className="photo-item">
          <img src={require("../images/unsplash_IgUR1iX0mqM.png")} />
        </div>
      </div>
    </div>
  );
}

export function Mission() {
  return (
    <div className="bg-blue">
      <div className="main-p py-5 popup-bg text-start text-white our-mission-container">
        <Grid
          container
          alignItems="center"
          className="h-100 py-2"
          style={{ marginBottom: "40px" }}
        >
          <Grid item xs={12} lg={2}>
            <h5>Our Mission</h5>
          </Grid>
          <Grid item xs={12} lg={6}>
            <p className="font-sm" style={{ margin: 0 }}>
              Anmol Network is on a mission to ease the onboarding of external
              users in the Blockchain space. Establishing it as the portal for
              Web2.0 to Web3.0 migrations for individuals, and businesses alike.
            </p>
          </Grid>
        </Grid>
        <Grid container alignItems="center" className="h-100 py-2">
          <Grid item xs={12} lg={2}>
            <h5>Our Vision</h5>
          </Grid>
          <Grid item xs={12} lg={6}>
            <p className="font-sm" style={{ margin: 0 }}>
              To onboard and facilitate One Billion (1,000 Million), new users,
              to the Blockchain space - while making Anmol a major player in
              on-chain and off-chain markets.
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

export function Jobs() {
  const [currentCategory, setCurrentCategory] = useState("All");
  const jobs_data = data.jobs;

  const changeFilter = (category) => {
    setCurrentCategory(category);
  };

  const jobs_data_html = jobs_data.map((i) => {
    if (i.job.category !== currentCategory) {
      if (currentCategory !== "All") {
        return false;
      }
    }

    return (
      <Grid item xs={12} lg={6} className="column">
        <Job
          title={i.job.title}
          type={i.job.type}
          mode={i.job.mode}
          location={i.job.location}
          posted={i.job.posted}
          category={i.job.category}
          currentCategory={currentCategory}
          description={i.job.description ? i.job.description : null}
          responsibilities={
            i.job.responsibilities ? i.job.responsibilities : null
          }
          qualifications={i.job.qualifications ? i.job.qualifications : null}
          preferred={i.job.preferred ? i.job.preferred : null}
          benefits={i.job.benefits ? i.job.benefits : null}
        />
      </Grid>
    );
  });

  return (
    <div className="py-5 bg-blue text-start text-white" id={"positions"}>
      <div className="main-p py-5">
        <h5>
          <strong>Available Positions</strong>
        </h5>
      </div>
      <div className="border-bottom jobs-fonts">
        <div className="w-100 main-p mb-3">
          <div
            className={`d-inline mr-3 filter-btn ${
              currentCategory === "All" && "active"
            }`}
            onClick={() => changeFilter("All")}
          >
            <p className="d-inline">All</p>
          </div>
          <div
            className={`d-inline mr-3 filter-btn ${
              currentCategory === "Engineering" && "active"
            }`}
            onClick={() => changeFilter("Engineering")}
          >
            <p className="d-inline">Engineering</p>
          </div>
          <div
            className={`d-inline mr-3 filter-btn ${
              currentCategory === "Creative" && "active"
            }`}
            onClick={() => changeFilter("Creative")}
          >
            <p className="d-inline">Creative</p>
          </div>
          <div
            className={`d-inline mr-3 filter-btn ${
              currentCategory === "Marketing" && "active"
            }`}
            onClick={() => changeFilter("Marketing")}
          >
            <p className="d-inline filter-btn">Growth&nbsp;&&nbsp;Marketing</p>
          </div>
        </div>
      </div>
      <div className="py-5 main-p">
        <Grid container spacing={6}>
          {jobs_data_html}
        </Grid>
      </div>
    </div>
  );
}

export function Job(props) {
  const {
    title,
    type,
    mode,
    location,
    posted,
    description,
    category,
    currentCategory,
    responsibilities,
    qualifications,
    preferred,
    benefits,
  } = props;

  const [isPopOpen, setIsPopOpen] = useState(false);

  const handlePopOpen = () => {
    setIsPopOpen(true);
  };

  return (
    <div className="row career-position">
      <div
        className="career-position-block col-9 popup-bg p-3 d-flex align-items-center"
        style={{ fontFamily: "Poppins" }}
      >
        <div className="row">
          <p className="mb-1">{title}</p>
          <div className="career-position-details">
            <span>{type}</span>
            <JobDot />
            <span>{mode}</span>
          </div>
          <p className="font-sm">Location: {location}</p>
        </div>
      </div>
      <div
        onClick={() => handlePopOpen()}
        style={{ cursor: "pointer" }}
        className="career-position-button col-3 purp-bg d-flex flex-column justify-content-center align-items-center"
      >
        <a>
          <p
            style={{ fontFamily: "Poppins" }}
            className="font-xs d-inline px-1"
          >
            Learn More
          </p>
          <img src={require("../images/Arrow 4.png")} />
        </a>
      </div>
      <div>
        <JobPopup
          title={title}
          type={type}
          mode={mode}
          location={location}
          posted={posted}
          description={description}
          responsibilities={responsibilities}
          qualifications={qualifications}
          preferred={preferred}
          benefits={benefits}
          isPopOpen={isPopOpen}
          setIsPopOpen={setIsPopOpen}
        />
      </div>
    </div>
  );
}

export const VideoBlock = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const setVideoStatus = () => {
    const video = document.getElementById("video");

    video.addEventListener(
      "click",
      function () {
        if (!isVideoPlaying) {
          video.play();
          setIsVideoPlaying(true);
        } else {
          video.pause();
          setIsVideoPlaying(false);
        }
      },
      false
    );
  };

  return (
    <>
      <Box flexGrow={1} className="text-white text-start">
        <Grid
          className="know-more-mob"
          container
          style={{ marginBottom: "100px", padding: "140px 150px 0 150px" }}
        >
          <Grid items xs={12} md lg={6} className="d-flex flex-column">
            <div className="text-start">
              <div className="back-link">
                <a href="/">
                  <img src={arrowLeft} />
                  Go back
                </a>
              </div>
              <h4 style={{ marginBottom: "15px" }}>
                <strong>Generative Art NFTs and More!</strong>
              </h4>
              <p className="font-sm" style={{ maxWidth: "480px" }}>
                Watch our timelapse demo where we create 38 millions NFTs in one
                minute with Anmol Moulds
              </p>
              <a
                href="http://earlyaccess.anmol.network/"
                target="_blank"
                className="pill-btn"
                style={{
                  marginTop: "15px",
                  padding: "11px 27px 11px 27px",
                  display: "inline-block",
                  textAlign: "center",
                  filter: "drop-shadow(0px 4px 16px rgba(255, 255, 255, 0.2))",
                }}
              >
                <strong>Get early access</strong>
              </a>
            </div>
          </Grid>
          <Grid
            items
            xs={12}
            md
            lg={6}
            className="d-flex flex-column justify-content-center"
          >
            <div
              style={{ border: "1px solid #ffffff", borderRadius: "10px" }}
              className="video"
              onClick={() => setVideoStatus()}
            >
              <video
                id="video"
                style={{ borderRadius: "10px" }}
                preload="auto"
                controls
                poster={videoPoster}
              >
                <source type="video/mp4" src={video} />
              </video>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export const AboutBlock = () => {
  return (
    <Box
      flexGrow={1}
      style={{ padding: "0 150px 0 150px" }}
      className="know-more-mob-content text-white text-start"
    >
      <div>
        <h5 className="mb-4">
          <strong>What are Anmol Moulds?</strong>
        </h5>
        <p className="mb-3">
          Moulds are NFT templates that allow users to create a collection of
          hundreds, thousands or even millions (given time and ANML tokens) of
          NFTs from a single set of attributes, layers, and uploaded assets, or
          from component modules in a library. Moulds are perfect for creating
          generative art NFT collections, as well as eCommerce, metaverse
          assets, GameFi, staking, logistics, ticketing systems, and nearly
          anything else you wish to create!
        </p>
        <p className="mb-3">
          Moulds for generative art NFT collections, such as Bored Ape, can be
          minted all at once, or the creator can allow the user to mint one or
          many random variations of the outputs possible from the combinations
          of attributes in the Mould and monetize from the buying, selling or
          trading of the randomized NFT.
        </p>
        <p className="mb-3">
          With Moulds, users can go beyond the same experience of an NFT upload
          form designed by a platform, and create their custom NFT template that
          can be used, bought, sold, leased or traded on Anmol Bazaar. Creators
          have complete control over the scarcity of attributes, the amount of
          NFTs which can be minted from the Mould, and much more!
        </p>
      </div>
    </Box>
  );
};

export const FaqBlock = () => {
  return (
    <Box
      flexGrow={1}
      className="know-more-mob-content text-white text-start"
      style={{
        padding: "0 150px 0 150px",
        marginTop: "70px",
        marginBottom: "100px",
      }}
    >
      <h5 className="mb4" style={{ marginBottom: "20px" }}>
        <strong>FAQs:</strong>
      </h5>
      {faqInfo.map((item, index) => {
        return (
          <details key={index} style={{ marginBottom: "20px" }}>
            <summary className="summary">
              {item.summary}
              <FaqArrow className="faq-arrow" />
            </summary>
            <p className="summary-text">{item.text}</p>
          </details>
        );
      })}
    </Box>
  );
};

const BrowserPanel = () => {
  return (
    <div className="browser-panel">
      <div className="browser-address">
        <div className="browser-controls">
          <span className="red" />
          <span className="yellow" />
          <span className="green" />
        </div>

        <span>
          <img src={slideBtn} />
          <img className="browser-arrow-down" src={arrowDown} />
        </span>
        <img className="browser-arrow-left" src={browserArrowLeft} />

        <div className="browser-domain">
          <div className="address">
            <img
              style={{ marginRight: "10px", marginBottom: "8px" }}
              src={anmolLogo}
            />
            <span>anmol.network/anmoltools</span>
            <img
              src={lock}
              className="address-lock"
              style={{ marginLeft: "10px", marginBottom: "2px" }}
            />
          </div>
          <img src={more} />
        </div>
      </div>
      <img className="browser-panel-cross" src={cross} />
    </div>
  );
};

const BrowserSidebar = ({ setContentName }) => {
  // const [sideText, setSideText] = useState('hello')
  //
  // const getSideText = (toolsName) => {
  //   const text = {
  //     nft: 'All of Anmol’s Tools are availble on many chains such as Polygon, Ethereum, BSC, Solana, and more!',
  //     social: 'All of Anmol’s Tools are availble on many chains such as Polygon, Ethereum, BSC, Solana, and more!',
  //     metaverse: 'All of Anmol’s Tools are availble on many chains such as Polygon, Ethereum, BSC, Solana, and more!',
  //     defi: 'All of Anmol’s Tools are availble on many chains such as Polygon, Ethereum, BSC, Solana, and more!',
  //     gamefi: 'All of Anmol’s Tools are availble on many chains such as Polygon, Ethereum, BSC, Solana, and more!'
  //   }
  //
  //   return setSideText(text[toolsName])
  // };

  const detailsHandler = () => {
    const details = document.querySelectorAll("details");
    details.forEach((targetDetail) => {
      targetDetail.addEventListener("click", () => {
        // Close all the details that are not targetDetail.
        details.forEach((detail) => {
          if (detail !== targetDetail) {
            detail.removeAttribute("open");
          }
        });
      });
    });
  };

  function makeActive(a) {
    const items = document.querySelectorAll(".tab-active");
    if (items.length) items[0].className = null;
    a.className = "tab-active";
  }

  useEffect(() => {
    detailsHandler();
  }, []);

  return (
    <div className="browser-side">
      <div className="browser-side-content">
        <div className="browser-side-title">
          <span>
            <strong>Anmol Tools</strong>
          </span>
        </div>
        <div className="browser-line" />
        <p className="browser-side-summary-text">
          All of Anmol’s Tools are availble on many chains such as Polygon,
          Ethereum, BSC, Solana, and more!
        </p>
        <details className="browser-side-summary" open>
          {" "}
          {/*id={'nftTools'}*/}
          <summary className="side-summary">
            NFT Tools
            <FaqArrow className="side-arrow" width="8" heigth="8" />
          </summary>
          <div className="browser-side-summary-content">
            <a
              href="javascript:;"
              className="tab-active"
              onClick={(e) => {
                setContentName("moulds");
                makeActive(e.target);
              }}
            >
              Moulds
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("fractonalization");
                makeActive(e.target);
              }}
            >
              Fractionalization
            </a>

            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("morphing");
                makeActive(e.target);
              }}
            >
              Morphing
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("ticketing");
                makeActive(e.target);
              }}
            >
              NFT Ticketing
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("threeNft");
                makeActive(e.target);
              }}
            >
              3D NFT & 3D NFT Moulds
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("collections");
                makeActive(e.target);
              }}
            >
              Collections
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("collages");
                makeActive(e.target);
              }}
            >
              Collages
            </a>
          </div>
        </details>

        <details className="browser-side-summary">
          <summary className="side-summary">
            Social Tools
            <FaqArrow className="side-arrow" width="8" heigth="8" />
          </summary>
          <div className="browser-side-summary-content">
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("communityDao");
                makeActive(e.target);
              }}
            >
              Community Based DAOs
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("airdrop");
                makeActive(e.target);
              }}
            >
              NFT Airdrop
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("fanTokens");
                makeActive(e.target);
              }}
            >
              Fan Tokens
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("poap");
                makeActive(e.target);
              }}
            >
              POAP NFTs
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("passports");
                makeActive(e.target);
              }}
            >
              NFT Passports
            </a>
          </div>
        </details>

        <details className="browser-side-summary">
          <summary className="side-summary">
            Metaverse Tools
            <FaqArrow className="side-arrow" width="8" heigth="8" />
          </summary>
          <div className="browser-side-summary-content">
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("customWear");
                makeActive(e.target);
              }}
            >
              Custom Wearables
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("augmented");
                makeActive(e.target);
              }}
            >
              Augmented Reality NFTs
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("avatar");
                makeActive(e.target);
              }}
            >
              Avatar Builder
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("virtualPets");
                makeActive(e.target);
              }}
            >
              Virtual Pets
            </a>
          </div>
        </details>

        <details className="browser-side-summary">
          <summary className="side-summary">
            DeFi Tools
            <FaqArrow className="side-arrow" width="8" heigth="8" />
          </summary>
          <div className="browser-side-summary-content">
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("defiCommerce");
                makeActive(e.target);
              }}
            >
              DeFi E-Commerce
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("mintToken");
                makeActive(e.target);
              }}
            >
              Mint Token
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("whiteWallet");
                makeActive(e.target);
              }}
            >
              Whitelabel Wallet
            </a>
          </div>
        </details>

        <details className="browser-side-summary">
          <summary className="side-summary">
            GameFi Tools
            <FaqArrow className="side-arrow" width="8" heigth="8" />
          </summary>
          <div className="browser-side-summary-content">
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("gameBuilder");
                makeActive(e.target);
              }}
            >
              Game Builder
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("gameAssets");
                makeActive(e.target);
              }}
            >
              In-Game Assets
            </a>
          </div>
        </details>
      </div>
    </div>
  );
};

const BrowserComingSoon = () => {
  return (
    <div className="coming-wrapper">
      <span className="coming-stay">STAY TUNED</span>
      <span className="coming-soon">
        COMING
        <span style={{ marginLeft: "10px" }}>SOON!</span>
      </span>
      <button
        onClick={() => {
          window.open("https://anmolnetwork.typeform.com/features", "_blank");
        }}
        className="pill-btn px-4 py-2"
      >
        Notify Me
      </button>
    </div>
  );
};

const BrowserContent = ({ title, text, img }) => {
  return (
    <div className="browser-content">
      <div className="browser-content-text text-white">
        <h5 className="mb-4">
          <strong>{title}</strong>
        </h5>
        {typeof text === "object" ? (
          Object.values(text).map((value) => {
            return <p>{value}</p>;
          })
        ) : (
          <p>{text}</p>
        )}
      </div>
      {img ? (
        <div className="browser-content-img">
          <img className="responsive-img" src={img} alt="" />
        </div>
      ) : (
        <BrowserComingSoon />
      )}
    </div>
  );
};

export const Browser = () => {
  const [contentName, setContentName] = useState("moulds");
  const isMobile = window.innerWidth < 922;
  const desktopContent = <BrowserSidebar setContentName={setContentName} />;

  const {
    moulds,
    fractionalization,
    morphing,
    nftTicketing,
    threeNft,
    collections,
    collages,
    communityDao,
    fanTokens,
    poap,
    passports,
    customWear,
    augmented,
    avatar,
    virtualPets,
    defiCommerce,
    mintToken,
    whiteWallet,
    gameAssets,
    gameBuilder,
    nftAirdrop,
  } = browserInfo;

  return (
    <Box
      flexGrow={1}
      className="py-5 bg-blue main-p"
      style={{ marginTop: "-1px" }}
    >
      <div>
        <BrowserPanel />
        <div className="browser-content-wrapper">
          {!isMobile ? desktopContent : <BrowserMobilePanel />}
          {(() => {
            if (isMobile) {
              return false;
            }
            if (contentName === "moulds")
              return (
                <BrowserContent
                  title={moulds?.title}
                  text={moulds?.text}
                  img={moulds?.img}
                  video={moulds?.video}
                />
              );
            if (contentName === "airdrop")
              return (
                <BrowserContent
                  title={nftAirdrop?.title}
                  text={nftAirdrop?.text}
                  img={nftAirdrop?.img}
                  video={nftAirdrop?.video}
                />
              );
            if (contentName === "fractonalization")
              return (
                <BrowserContent
                  title={fractionalization?.title}
                  text={fractionalization?.text}
                  img={fractionalization?.img}
                />
              );
            if (contentName === "morphing")
              return (
                <BrowserContent
                  title={morphing?.title}
                  text={morphing?.text}
                  img={morphing?.img}
                />
              );
            if (contentName === "ticketing")
              return (
                <BrowserContent
                  title={nftTicketing?.title}
                  text={nftTicketing?.text}
                  img={nftTicketing?.img}
                />
              );
            if (contentName === "threeNft")
              return (
                <BrowserContent
                  title={threeNft?.title}
                  text={threeNft?.text}
                  img={threeNft?.img}
                />
              );
            if (contentName === "collections")
              return (
                <BrowserContent
                  title={collections?.title}
                  text={collections?.text}
                  img={collections?.img}
                />
              );
            if (contentName === "collages")
              return (
                <BrowserContent
                  title={collages?.title}
                  text={collages?.text}
                  img={collages?.img}
                />
              );

            if (contentName === "communityDao")
              return (
                <BrowserContent
                  title={communityDao?.title}
                  text={communityDao?.text}
                  img={communityDao?.img}
                />
              );
            if (contentName === "fanTokens")
              return (
                <BrowserContent
                  title={fanTokens?.title}
                  text={fanTokens?.text}
                  img={fanTokens?.img}
                />
              );
            if (contentName === "poap")
              return (
                <BrowserContent
                  title={poap?.title}
                  text={poap?.text}
                  img={poap?.img}
                />
              );
            if (contentName === "passports")
              return (
                <BrowserContent
                  title={passports?.title}
                  text={passports?.text}
                  img={passports?.img}
                />
              );

            if (contentName === "customWear")
              return (
                <BrowserContent
                  title={customWear?.title}
                  text={customWear?.text}
                  img={customWear?.img}
                />
              );
            if (contentName === "augmented")
              return (
                <BrowserContent
                  title={augmented?.title}
                  text={augmented?.text}
                  img={augmented?.img}
                />
              );
            if (contentName === "avatar")
              return (
                <BrowserContent
                  title={avatar?.title}
                  text={avatar?.text}
                  img={avatar?.img}
                />
              );
            if (contentName === "virtualPets")
              return (
                <BrowserContent
                  title={virtualPets?.title}
                  text={virtualPets?.text}
                  img={virtualPets?.img}
                />
              );

            if (contentName === "defiCommerce")
              return (
                <BrowserContent
                  title={defiCommerce?.title}
                  text={defiCommerce?.text}
                  img={defiCommerce?.img}
                />
              );
            if (contentName === "mintToken")
              return (
                <BrowserContent
                  title={mintToken?.title}
                  text={mintToken?.text}
                  img={mintToken?.img}
                  video={mintToken?.video}
                />
              );
            if (contentName === "whiteWallet")
              return (
                <BrowserContent
                  title={whiteWallet?.title}
                  text={whiteWallet?.text}
                  img={whiteWallet?.img}
                />
              );

            if (contentName === "gameBuilder")
              return (
                <BrowserContent
                  title={gameBuilder?.title}
                  text={gameBuilder?.text}
                  img={gameBuilder?.img}
                />
              );
            if (contentName === "gameAssets")
              return (
                <BrowserContent
                  title={gameAssets?.title}
                  text={gameAssets?.text}
                  img={gameAssets?.img}
                />
              );
            else return <BrowserComingSoon />;
          })()}
        </div>
      </div>
    </Box>
  );
};

export const BrowserMobilePanel = () => {
  const [category, setCategory] = useState(0);
  const [openedIndex, setOpenedIndex] = useState(null);

  const {
    moulds,
    fractionalization,
    morphing,
    nftTicketing,
    threeNft,
    collections,
    collages,
    communityDao,
    fanTokens,
    poap,
    passports,
    customWear,
    augmented,
    avatar,
    virtualPets,
    defiCommerce,
    mintToken,
    whiteWallet,
    gameAssets,
    gameBuilder,
    nftAirdrop,
  } = browserInfo;

  const content = [
    [
      moulds,
      nftAirdrop,
      fractionalization,
      morphing,
      nftTicketing,
      threeNft,
      collections,
      collages,
    ],
    [communityDao, nftAirdrop, fanTokens, poap, passports],
    [customWear, augmented, avatar, virtualPets],
    [defiCommerce, mintToken, whiteWallet],
    [gameAssets, gameBuilder],
  ];

  const changeCategory = (i) => {
    setOpenedIndex(null);
    setCategory(i);
  };

  return (
    <div className="browser-mobile-wrapper">
      <div className="browser-mobile-title">Anmol Tools</div>

      <div className="browser-mobile-nav">
        <div
          className={`item ${category === 0 && "active"}`}
          onClick={() => changeCategory(0)}
        >
          NFT Tools
        </div>
        <div
          className={`item ${category === 1 && "active"}`}
          onClick={() => changeCategory(1)}
        >
          Social Tools
        </div>
        <div
          className={`item ${category === 2 && "active"}`}
          onClick={() => changeCategory(2)}
        >
          Metaverse Tools
        </div>
        <div
          className={`item ${category === 3 && "active"}`}
          onClick={() => changeCategory(3)}
        >
          DeFi Tools
        </div>
        <div
          className={`item ${category === 4 && "active"}`}
          onClick={() => changeCategory(4)}
        >
          GameFi Tools
        </div>

        <div className="browser-mobile"></div>
      </div>

      <div className="browser-mobile-subs">
        {content[category].map((i, index) => {
          return (
            <BrowserMobileItem
              i={i}
              key={index}
              index={index}
              openedIndex={openedIndex}
              setOpenedIndex={setOpenedIndex}
            />
          );
        })}
      </div>
    </div>
  );
};

export const BrowserMobileItem = ({
  i,
  index,
  openedIndex,
  setOpenedIndex,
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  const openMenuHandler = () => {
    setOpenedIndex(index);
    if (openedIndex === index && openMenu === true) {
      return false;
    }

    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    if (openedIndex !== index) {
      setOpenMenu(false);
    }
  }, [openedIndex, setOpenMenu]);

  return (
    <div
      className={`browser-mobile-sub-item ${openMenu && "active"}`}
      onClick={() => openMenuHandler()}
    >
      <h4>{i.name}</h4>
      {i?.title ?? <h5>{i?.title}</h5>}
      {typeof i?.text === "object" ? (
        Object.values(i?.text).map((value) => {
          return <p>{value}</p>;
        })
      ) : (
        <p>{i?.text}</p>
      )}
      {i?.img ? (
        <img src={i?.img} alt="picture item" />
      ) : i?.video ? (
        <video src={video} controls loop muted />
      ) : (
        <BrowserComingSoon />
      )}
    </div>
  );
};

export const CarouselMainPage = () => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <>
      <Swiper
        slidesPerView={1}
        ref={sliderRef}
        loop
        // loop={window.innerWidth < 922}
        modules={[EffectFade, Pagination]}
        pagination={{ clickable: true }}
        effect="fade"
        style={{ marginTop: "-1px" }}
      >
        <SwiperSlide>
          <>
            <Box flexGrow={1}>
              <div className="slide-wrapper">
                <div className="slide-text">
                  <h5>NFT Trees</h5>
                  <p>
                    Community is everything. Our interactions with others help
                    define us. For this reason, Anmol has positioned itself as a
                    bastion of community building, maintenance, and flourishing.
                    This largely happens through our DAO-based communities, but
                    it is also the major driving force behind our interoperable
                    efforts. Community spirit is baked right into our code.
                  </p>
                  <SliderArrowLeft
                    className="slider-arrow"
                    style={{ marginRight: "40px" }}
                    onClick={() => handlePrev()}
                  />
                  <SliderArrowRight
                    className="slider-arrow"
                    onClick={() => handleNext()}
                  />
                </div>
                <img src={tree} alt="" />
              </div>
            </Box>
          </>
        </SwiperSlide>
        <SwiperSlide>
          <>
            <Box flexGrow={1}>
              <div className="slide-wrapper">
                <div className="slide-text">
                  <h5>Blockchain Literacy</h5>
                  <p>
                    Anmol has{" "}
                    <a
                      href="https://medium.com/anmolnetwork/anmol-and-darwinia-announce-partnership-and-blockchain-literacy-campaign-4de569d30a54"
                      target="_blank"
                    >
                      partnered with Darwinia
                    </a>{" "}
                    to launch our own blockchain literacy campaign. We will be
                    educating the masses both on how to use blockchain tech, and
                    how blockchain tech can help enrich and flourish their
                    lives. We firmly believe this industry can only reach its
                    full potential if it becomes directly engaged with all our
                    livelihoods.{" "}
                  </p>
                  <SliderArrowLeft
                    className="slider-arrow"
                    style={{ marginRight: "40px" }}
                    onClick={() => handlePrev()}
                  />
                  <SliderArrowRight
                    className="slider-arrow"
                    onClick={() => handleNext()}
                  />
                </div>
                <img src={literacy} alt="" />
              </div>
            </Box>
          </>
        </SwiperSlide>
        {/*<div className="modile-arrows">*/}
        {/*  <SliderArrowLeft className='slider-arrow left-arrow slider-arrow-mob' style={{marginRight: '40px'}} onClick={() => handlePrev()}/>*/}
        {/*  <SliderArrowRight className='slider-arrow slider-arrow-mob' onClick={() => handleNext()}/>*/}
        {/*</div>*/}
      </Swiper>
    </>
  );
};
