import styled from "styled-components";
import PayPart from "../main/PayPart";
import Footer from "../main/Footer";
import CareerIntro from "../main/CareerIntro";
import Mission from "../main/MissionPart";
import { Jobs } from "../main/jobs";

export default function Careers() {
  return (
    <PageSec>
      <CareerIntro />
      <Mission />
      <Jobs />
      <PayPart />
      <Footer />
    </PageSec>
  );
}

const PageSec = styled.div``;
