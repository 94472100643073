import React, { useRef } from "react";
import { MemberDesc } from "./popup";
import arrow from "../Assets/memberArrow.svg";
import styled from "styled-components";
import GlassCard from "../Assets/aboutUs/GlassCard.png";

export const Member = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const boxRef = useRef(null);

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const { name, designation, image, about, tags, linkedin } = props;
  return (
    <MemberBox
      ref={boxRef}
      style={{
        minHeight: boxRef?.current?.offsetWidth
          ? boxRef.current.offsetWidth + "px"
          : "340px",
      }}
    >
      <MermberContent>
        <img src={image} className="w-100" />
        <img
          src={require("../images/Rectangle 292.png")}
          className="purp-overlay"
        />
        <MmberInfo onClick={(e) => handlePopoverOpen(e)}>
          <img
            src={arrow}
            className="arrow"
            style={{ position: "absolute", top: 20, right: 20 }}
          />
          <div className="row">
            <div className="col-10 px-2">
              <p className="m-0">
                <strong>{name}</strong>
              </p>
            </div>
          </div>
          <div className="row px-2">
            <p className="m-0 p-0">{designation}</p>
          </div>
        </MmberInfo>
        <div className="col-1 p-0 text-end">
          <MemberDesc
            name={name}
            designation={designation}
            img={image}
            about={about}
            tags={tags}
            anchorEl={anchorEl}
            handlePopoverClose={handlePopoverClose}
            linkedin={linkedin}
          />
        </div>
      </MermberContent>
      {/*{console.log(anchorEl)}*/}
    </MemberBox>
  );
};

const MemberBox = styled.div`
  position: relative;
  width: 345px;
  max-width: 400px;
  min-width: 200px;
  padding: 1px;
  background: linear-gradient(
    180deg,
    #fd1b6d 0%,
    rgba(167, 0, 209, 0.73) 46.15%,
    #40affe 100%
  );
  border-radius: 10px;
  overflow: hidden;
`;

const MermberContent = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
`;

const MmberInfo = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  cursor: pointer;
  padding: 20px;
  text-align: left;
  background: rgba(3, 0, 39, 0.4);
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
`;
