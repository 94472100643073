import styled from "styled-components";
import IntroImage from "../Assets/Careers/titleImage.png";
import TitleBackImage from "../Assets/Careers/titleBack.png";

export default function CareerIntro() {
  return (
    <PartSec>
      <TitlePart>
        <h1>Find Your Future With Anmol Careers</h1>
        <h2>
          We, at Anmol, are building a team of awesome individuals, experts in
          their domain, ready
          <br />
          to tackle challenges that will ultimately make Anmol Web3 Toolkit
          available to masses
          <br /> and allow them to learn the art of creating their own NFTs. If
          you think you see a future
          <br /> for yourself in this field then you are at the right place.
        </h2>
        <ButtonBox>
          <button
            onClick={() => {
              document
                .getElementById("positions")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            See Available Positions
          </button>
        </ButtonBox>
      </TitlePart>
      <TitleImage>
        <img src={IntroImage} alt="Anmol Careers" />
      </TitleImage>
      <div></div>
    </PartSec>
  );
}

const PartSec = styled.div`
  padding: 125px 9vw 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${TitleBackImage}) bottom/cover no-repeat;
  min-height: 700px;

  @media (max-width: 1025px) {
    flex-direction: column;
    padding: 5vh 9vw 10vh;
    height: unset;
  }
`;

const TitlePart = styled.div`
  padding-top: 87px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;

  & > h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #ffffff;
    margin-bottom: 20px;
  }

  & > h2 {
    font-family: "Hind";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    margin-bottom: 22px;
    tex-align: cceter;
  }
`;

const ButtonBox = styled.div`
  background: linear-gradient(#fc1ba7 100%, #000000 0%, #40affd 100%);
  width: 198px;
  height: 46px;
  padding: 1px;
  border-radius: 23px;
  overflow: hidden;

  & > button {
    background: linear-gradient(90deg, #9230af 4.09%, #181058 94.03%);
    border-radius: 23px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: none;
    outline: none;
    font-family: "Hind";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;

    &:hover {
      background: linear-gradient(90.21deg, #ff4ff5 2.06%, #9230af 81.13%);
    }
  }
`;

const TitleImage = styled.div`
  width: 70%;
  margin-bottom: 75px;
  margin-top: -10%;
  @media (max-width: 1025px) {
    width: 100%;
  }
  & > img {
    width: 100%;
    height: auto;
  }
`;
