import styled from "styled-components";
import Grid from "@mui/material/Grid";
import VideoPlayer from "./VideoPlayer";

export default function IntroPart() {
  return (
    <IntroPartSec className="d-flex flex-column justify-content-lg-start ">
      {/*<Box className="h-100 ">*/}
      <PageTitle>The Ultimate No-Code Builder for Web3</PageTitle>
      <Grid container spacing={1} className="h-100">
        <Grid items xs={12} lg={6} md className="column">
          <IntroText>
            <h2>Think of us like a Wordpress or Canva, but for Web3!</h2>
            <p>
              <br />
              We offer simple and easy-to-use one-click solutions to help
              businesses or users integrate blockchain and Web3 technologies
              into their business model or digital identity with Multi-Chain
              DApps and Tools that output customized and branded interfaces for
              end users. Anmol’s Tools can also integrate with any project to
              drive community engagement of existing blockchain users while
              fostering organic grassroots growth. With Anmol, anyone is
              empowered to start a DAO, mint a token, or launch a generative art
              NFT project, and much more with a few clicks of a button.
            </p>
            <div>
              <div>
                <button onClick={()=>{window.open("https://app.anmol.network","_blank")}}>
                    Launch App
                </button>
              </div>
              <div>
                <button onClick={()=>{window.open("https://www.youtube.com/@AnmolNetworkVideos","_blank")}}>
                    Watch Demos
                </button>
              </div>
            </div>
          </IntroText>
        </Grid>

        <Grid items xs={12} md lg={6} className="column">
          <VideoBox>
            <VideoPlayerElement>
              <VideoPlayer src={"/homePage.mp4"}></VideoPlayer>
            </VideoPlayerElement>
          </VideoBox>
        </Grid>
      </Grid>

      {/*</Box>*/}
    </IntroPartSec>
  );
}

const IntroPartSec = styled.div`
  padding: 0 100px;
  @media (max-width: 480px) {
    padding: 0 20px;
  }
`;

const PageTitle = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 51px;
  line-height: 110%;
  text-transform: capitalize;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 174px;
  margin-bottom: 91px;

  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 140%;
  }
`;

const IntroText = styled.div`
  text-align: left;
  padding-right: 38px;

  @media (max-width: 1200px) {
    text-align: center;
  }

  @media (max-width: 899px) {
    padding-right: 0;
  }

  & > h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    margin-bottom: 71px;
  }

  & > p {
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    margin-bottom: 60px;

    & > span {
      font-weight: 700;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      height: 46px;
      width: 159px;
      margin: 0 35px;
      padding: 1.5px;
      background: Linear-Gradient(#fe18a6, #5761d7, #448bdd);
      border-radius: 23px;

      & > button {
        background: linear-gradient(90.21deg, #9230af 2.06%, #181058 81.13%);
        border-radius: 67px;
        width: 100%;
        height: 100%;
        border: none;
        font-family: "Hind";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #ffffff;

        &:hover {
          background: linear-gradient(90.21deg, #ff4ff5 2.06%, #9230af 81.13%);
        }
      }

      @media (max-width: 480px) {
        margin: 0 10px;
      }
    }
  }
`;

const VideoBox = styled.div`
  width: 100%;
  height: 100%;
  background: Linear-Gradient(#fe18a6, #5761d7, #448bdd);
  padding: 1px;
  border-radius: 10px;

  @media (max-width: 1200px) {
    min-height: 30vh;
    margin-top: 60px;
  }
`;

const VideoPlayerElement = styled.div`
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: 8px;
  overflow: hidden;
`;
