import React, { useRef } from "react";
import {
  FirstSection,
  Intro,
  Desc,
  Jobs,
  Backers2,
  Backers,
  Mission,
  TemplateTool,
  MultiChain,
  MissionStatement,
  FindFuture,
  VideoBlock,
  AboutBlock,
  FaqBlock,
  Browser,
  CarouselMainPage,
} from "../main";
import AnmolTools from "../main/AnmolTool";
import IntroPart from "../main/IntroPart";
import NewsPart from "../main/NewsPart";
import Partners from "../main/Partners";
import PayPart from "../main/PayPart";
import Footer from "../main/Footer";
import styled from "styled-components";

//background image
import LeftRock from "../Assets/newHome/left-rock.png";
import RightRock from "../Assets/newHome/right-rock.png";
import Swirl from "../Assets/newHome/swirl.png";
import TopLight from "../Assets/newHome/topLight.png";
import Cosmo from "../Assets/newHome/cosmo.png";

export function MainPage() {
  return (
    <PageSec>
      <PageBack>
        <LeftRoCkImage src={LeftRock} alt="left rock" />
        <RightRockImage src={RightRock} alt="right rock" />
        <TopLightImage src={TopLight} alt="top light" />
        <SwirlImage src={Swirl} alt="swirl" />
        <CosmoImage src={Cosmo} alt="cosmo" />
      </PageBack>
      <PageContent>
        <IntroPart />
        <Partners />
        <AnmolTools />
        <NewsPart />
        <PayPart />
        <Footer />
      </PageContent>

      {/* <FirstSection />
      <Intro />
      <Desc />
      <Backers2 />
      <Backers />
      <Browser /> */}
      {/*<TemplateTool/>*/}
      {/* <MultiChain /> */}
      {/* <CarouselMainPage /> */}
      {/* <Footer /> */}
    </PageSec>
  );
}

const PageSec = styled.section`
  position: relative;
`;

const PageBack = styled.div`
  position: absolute;
  z-index: 1;
  top: 50px;
  bottom: 450px;
  right: 0;
  left: 0;
`;

const LeftRoCkImage = styled.img`
  position: absolute;
  width: 26vw;
  height: auto;
  top: 50px;
  left: 0;
  object-position: left;
`;

const RightRockImage = styled.img`
  position: absolute;
  width: 14vw;
  height: auto;
  top: 817px;
  right: 0;
  object-position: right;
`;
const TopLightImage = styled.img`
  position: absolute;
  width: 100vw;
  height: auto;
  top: 0;
  left: 0;
  object-position: left;
`;
const SwirlImage = styled.img`
  position: absolute;
  width: 60vw;
  height: auto;
  top: 0;
  right: 0;
  object-position: right;
`;
const CosmoImage = styled.img`
  position: absolute;
  width: 100vw;
  height: auto;
  bottom: 0;
  left: 0;
  object-position: bottom;
`;

const PageContent = styled.div`
  position: relative;
  z-index: 5;
`;

export function Teams() {
  return (
    <>
      <div className="second-bg" style={{ backgroundColor: "#040F2E" }}>
        <MissionStatement />
      </div>
      <Footer />
    </>
  );
}

export function Careers() {
  return (
    <div className="bg-blue overflow-hidden">
      <FindFuture />
      <Mission />
      <Jobs />
      <Footer />
    </div>
  );
}

export function KnowMore() {
  return (
    <div className="main-bg">
      <VideoBlock />
      <AboutBlock />
      <FaqBlock />
      <Footer />
    </div>
  );
}
