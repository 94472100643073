import styled from "styled-components";
import AnmolLogo from "../Assets/Logo/Logo_footer.svg";
import TwitterLogo from "../Assets/Social/Twitter.svg";
import DiscordLogo from "../Assets/Social/Discord.svg";
import TelegramLogo from "../Assets/Social/Telegram.svg";
import LinkedinLogo from "../Assets/Social/LinkedIn.svg";
import MediumLogo from "../Assets/Social/Medium.svg";
import { PrivacyPolicy, TermsAndConditions } from "./popup";

export default function Footer() {
  return (
    <FooterSec>
      <FooterBody>
        <FooterLinks>
          <h3>Important Links</h3>
          <div>
            <a href="https://github.com/anmolnetwork/" target="_blank">
              GitHub
            </a>
            <a href="/team">About Us</a>
            <a href="/careers">Careers</a>
            <a href="https://litepaper.anmol.network/">Lightpaper</a>
          </div>
        </FooterLinks>
        <FooterSocials>
          <h3>Social</h3>
          <div>
            <TwitterLink
              href="https://twitter.com/AnmolNetwork"
              target="_blank"
            />
            <DiscordLink href="https://discord.gg/zZHqMwq23X" target="_blank" />
            <TelegramLink href="https://t.me/anmolnetwork" target="_blank" />
            <LinkedInLink
              href="https://www.linkedin.com/company/anmolnetwork/"
              target="_blank"
            />
            <MediumLink
              href="https://medium.com/anmolnetwork"
              target="_blank"
            />
          </div>
          <Email onClick={()=>{
            var link = document.createElement("a");
            link.href = `mailto:hello@anmol.network`;
            link.target = "_blank";
            link.click();
          }
          }>hello@anmol.network</Email>
        </FooterSocials>
      </FooterBody>
      <FooterDown>
        <Copyright>© Anmol Network . All rights reserved.</Copyright>
        <Logo onClick={()=>{
          window.location.href = "/";
        }
        }>
          <img src={AnmolLogo} alt="Anmol" />
        </Logo>
        <Policy>
          <PolicyLink>
            <PrivacyPolicy />
          </PolicyLink>
          <span />
          <ConditionLink>
            <TermsAndConditions />
          </ConditionLink>
        </Policy>
      </FooterDown>
    </FooterSec>
  );
}

const FooterSec = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #1d0048;
`;

const FooterBody = styled.div`
  display: flex;
  padding: 50px 9vw 80px;
  width: 100%;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterLinks = styled.div`
  width: 33.33%;

  @media (max-width: 1200px) {
    width: 100%;
    order: 1;
  }

  & > h3 {
    font-family: "Hind";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #ffffff;
    margin-bottom: 15px;
    text-align: left;
    width: fit-content;

    @media (max-width: 1200px) {
      text-align: center;
      width: 100%;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    @media (max-width: 1200px) {
      align-items: center;
    }

    a {
      font-family: "Hind";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: #ffffff;
      margin: 5px 0;
      text-align: left;
      width: fit-content;

      @media (max-width: 1200px) {
        text-align: center;
      }
    }
  }
`;

const FooterSocials = styled.div`
  width: 33.33%;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1200px) {
    width: 100%;
    order: 0;
    margin-bottom: 30px;
  }

  & > h3 {
    font-family: "Hind";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #ffffff;
    margin-bottom: 20px;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;

    & > a {
      display: block;
      width: 24px;
      height: 24px;
      margin: 0 9px;
    }
  }
`;

const TwitterLink = styled.a`
  background: url(${TwitterLogo}) center/contain no-repeat;
`;

const DiscordLink = styled.a`
  background: url(${DiscordLogo}) center/contain no-repeat;
`;

const TelegramLink = styled.a`
  background: url(${TelegramLogo}) center/contain no-repeat;
`;

const LinkedInLink = styled.a`
  background: url(${LinkedinLogo}) center/contain no-repeat;
`;

const MediumLink = styled.a`
  background: url(${MediumLogo}) center/contain no-repeat;
`;

const Email = styled.p`
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #ffffff;
  margin-top: 22px;
  cursor: pointer;
`;

const FooterDown = styled.div`
  display: flex;
  width: 100%;
  padding: 0 7vw 38px;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Copyright = styled.div`
  width: 33.33%;
  font-family: "Hind";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #ffffff;
  text-align: left;
  height: fit-content;
  margin-top: auto;

  @media (max-width: 1200px) {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const Logo = styled.div`
  width: 33.33%;
  display: flex;

  @media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 20px;
  }

  & > img {
    width: 110px;
    height: auto;
    margin: auto;
    margin-bottom: 8px;
  }
  cursor: pointer;
`;

const Policy = styled.div`
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: fit-content;
  margin-top: auto;

  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center;
  }

  & > span {
    width: 1px;
    height: 17px;
    margin: 0 20px;
    background: #fff;
    display: block;
  }

  & > a {
    font-family: "Hind";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #ffffff;
  }
`;

const PolicyLink = styled.a``;

const ConditionLink = styled.a``;
