import styled from "styled-components";
import Background from "../Assets/aboutUs/pageBack.png";
import MissionStatement from "../main/MissionStatement";
import { Team } from "../main/team";
import PayPart from "../main/PayPart";
import Footer from "../main/Footer";

export default function AboutUs() {
  return (
    <section>
      <BackgroundBox>
        <Team />
        <MissionStatement />
      </BackgroundBox>
      <PayPart />
      <Footer />
    </section>
  );
}

const BackgroundBox = styled.section`
  background: url(${Background}) bottom/cover no-repeat; ;
`;
