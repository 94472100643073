import styled from "styled-components";
import data from "../data/data.json";

export default function PayPart() {
  const payOptions = data.pay;
  const payElements = payOptions.map((item) => (
    <img key={item.name} src={item.image} alt={item.name} />
  ));
  return (
    <PayPartSection>
      <div>{payElements}</div>
    </PayPartSection>
  );
}

const PayPartSection = styled.div`
  background: #3c1466;
  height: 117px;
  width: 100%;

  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 480px) {
      flex-wrap: wrap;
    }

    & > img {
      margin: 0 20px;

      @media (max-width: 1200px) {
        margin: 0 10px;
      }
    }
  }
`;
