import styled from "styled-components";
import ToolImage from "../Assets/toolExample.png";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import slideBtn from "../Assets/BrowserIcons/slideBtn.svg";
import arrowDown from "../Assets/BrowserIcons/Arrow.svg";
import { ReactComponent as FaqArrow } from "../Assets/faqArrow.svg";
import { browserInfo, faqInfo } from "../constants";
import browserArrowLeft from "../Assets/BrowserIcons/browserArrowLeft.svg";
import anmolLogo from "../Assets/BrowserIcons/Anmol.svg";
import more from "../Assets/BrowserIcons/More.svg";
import lock from "../Assets/BrowserIcons/Lock.svg";
import cross from "../Assets/BrowserIcons/Cross.svg";
import ToolLogos from "../Assets/toolLogos.svg";
import useWidth from "../hooks/useWidth";
import data from "../data/data.json";

export default function AnmolTools() {
  return (
    <ToolPart>
      <h2>Anmol’s Tools</h2>
      <ToolContainer>
        <ToolBox>
          <Browser />
        </ToolBox>
      </ToolContainer>
    </ToolPart>
  );
}

export const Browser = () => {
  const [contentName, setContentName] = useState("moulds");
  const width = useWidth();
  const isMobile = width < 922;
  const desktopContent = <BrowserSidebar setContentName={setContentName} />;

  const {
    moulds,
    nftAirdrop,
    fractionalization,
    morphing,
    nftTicketing,
    threeNft,
    collections,
    collages,
    communityDao,
    fanTokens,
    poap,
    passports,
    customWear,
    augmented,
    avatar,
    virtualPets,
    defiCommerce,
    mintToken,
    whiteWallet,
    gameAssets,
    gameBuilder,
  } = browserInfo;

  return (
    <ToolScreen>
      <Box flexGrow={1}>
        <div>
          <BrowserCOntentWrapper className="browser-content-wrapper">
            {!isMobile ? desktopContent : <BrowserMobilePanel />}
            {(() => {
              if (isMobile) {
                return false;
              }
              if (contentName === "moulds")
                return (
                  <BrowserContent
                    title={moulds?.title}
                    text={moulds?.text}
                    img={moulds?.img}
                    video={moulds?.video}
                  />
                );
              if (contentName === "airdrop")
                return (
                  <BrowserContent
                    title={nftAirdrop?.title}
                    text={nftAirdrop?.text}
                    img={nftAirdrop?.img}
                    video={nftAirdrop?.video}
                  />
                );
              if (contentName === "fractonalization")
                return (
                  <BrowserContent
                    title={fractionalization?.title}
                    text={fractionalization?.text}
                    img={fractionalization?.img}
                  />
                );
              if (contentName === "morphing")
                return (
                  <BrowserContent
                    title={morphing?.title}
                    text={morphing?.text}
                    img={morphing?.img}
                  />
                );
              if (contentName === "ticketing")
                return (
                  <BrowserContent
                    title={nftTicketing?.title}
                    text={nftTicketing?.text}
                    img={nftTicketing?.img}
                  />
                );
              if (contentName === "threeNft")
                return (
                  <BrowserContent
                    title={threeNft?.title}
                    text={threeNft?.text}
                    img={threeNft?.img}
                  />
                );
              if (contentName === "collections")
                return (
                  <BrowserContent
                    title={collections?.title}
                    text={collections?.text}
                    img={collections?.img}
                  />
                );
              if (contentName === "collages")
                return (
                  <BrowserContent
                    title={collages?.title}
                    text={collages?.text}
                    img={collages?.img}
                  />
                );

              if (contentName === "communityDao")
                return (
                  <BrowserContent
                    title={communityDao?.title}
                    text={communityDao?.text}
                    img={communityDao?.img}
                  />
                );
              if (contentName === "fanTokens")
                return (
                  <BrowserContent
                    title={fanTokens?.title}
                    text={fanTokens?.text}
                    img={fanTokens?.img}
                  />
                );
              if (contentName === "poap")
                return (
                  <BrowserContent
                    title={poap?.title}
                    text={poap?.text}
                    img={poap?.img}
                  />
                );
              if (contentName === "passports")
                return (
                  <BrowserContent
                    title={passports?.title}
                    text={passports?.text}
                    img={passports?.img}
                  />
                );

              if (contentName === "customWear")
                return (
                  <BrowserContent
                    title={customWear?.title}
                    text={customWear?.text}
                    img={customWear?.img}
                  />
                );
              if (contentName === "augmented")
                return (
                  <BrowserContent
                    title={augmented?.title}
                    text={augmented?.text}
                    img={augmented?.img}
                  />
                );
              if (contentName === "avatar")
                return (
                  <BrowserContent
                    title={avatar?.title}
                    text={avatar?.text}
                    img={avatar?.img}
                  />
                );
              if (contentName === "virtualPets")
                return (
                  <BrowserContent
                    title={virtualPets?.title}
                    text={virtualPets?.text}
                    img={virtualPets?.img}
                  />
                );

              if (contentName === "defiCommerce")
                return (
                  <BrowserContent
                    title={defiCommerce?.title}
                    text={defiCommerce?.text}
                    img={defiCommerce?.img}
                  />
                );
              if (contentName === "mintToken")
                return (
                  <BrowserContent
                    title={mintToken?.title}
                    text={mintToken?.text}
                    img={mintToken?.img}
                    video={mintToken?.video}
                  />
                );
              if (contentName === "whiteWallet")
                return (
                  <BrowserContent
                    title={whiteWallet?.title}
                    text={whiteWallet?.text}
                    img={whiteWallet?.img}
                  />
                );

              if (contentName === "gameBuilder")
                return (
                  <BrowserContent
                    title={gameBuilder?.title}
                    text={gameBuilder?.text}
                    img={gameBuilder?.img}
                  />
                );
              if (contentName === "gameAssets")
                return (
                  <BrowserContent
                    title={gameAssets?.title}
                    text={gameAssets?.text}
                    img={gameAssets?.img}
                  />
                );
              else return <BrowserComingSoon />;
            })()}
          </BrowserCOntentWrapper>
        </div>
      </Box>
      <LogoBox>
        <h3>Our tools are deployed on multiple chains!</h3>
        <ToolLogoBox>
          {data.tools.map((item) => (
            <ToolLogo href={item.link}>
              <img src={item.image} alt={item.name} />
            </ToolLogo>
          ))}
        </ToolLogoBox>
      </LogoBox>
    </ToolScreen>
  );
};

const BrowserCOntentWrapper = styled.div`
  position: relative;

  @media (min-width: 1200px) {
    padding-left: 200px;
  }
`;

const ToolLogoBox = styled.div`
  object-fit: contain;
  margin-bottom: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToolLogo = styled.div`
  width: 22px;
  height: 22px;
  margin: 0 8px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const ToolScreen = styled.div`
  @media (max-width: 1200px) {
    video {
      width: 100%;
    }
  }
`;

const BrowserSidebar = ({ setContentName }) => {
  // const [sideText, setSideText] = useState('hello')
  //
  // const getSideText = (toolsName) => {
  //   const text = {
  //     nft: 'All of Anmol’s Tools are availble on many chains such as Polygon, Ethereum, BSC, Solana, and more!',
  //     social: 'All of Anmol’s Tools are availble on many chains such as Polygon, Ethereum, BSC, Solana, and more!',
  //     metaverse: 'All of Anmol’s Tools are availble on many chains such as Polygon, Ethereum, BSC, Solana, and more!',
  //     defi: 'All of Anmol’s Tools are availble on many chains such as Polygon, Ethereum, BSC, Solana, and more!',
  //     gamefi: 'All of Anmol’s Tools are availble on many chains such as Polygon, Ethereum, BSC, Solana, and more!'
  //   }
  //
  //   return setSideText(text[toolsName])
  // };

  const detailsHandler = () => {
    const details = document.querySelectorAll("details");
    details.forEach((targetDetail) => {
      targetDetail.addEventListener("click", () => {
        // Close all the details that are not targetDetail.
        details.forEach((detail) => {
          if (detail !== targetDetail) {
            detail.removeAttribute("open");
          }
        });
      });
    });
  };

  function makeActive(a) {
    const items = document.querySelectorAll(".tab-active");
    if (items.length) items[0].className = null;
    a.className = "tab-active";
  }

  useEffect(() => {
    detailsHandler();
  }, []);

  return (
    <BrowserSide>
      <div className="browser-side-content">
        <details className="browser-side-summary" open>
          {" "}
          {/*id={'nftTools'}*/}
          <summary className="side-summary">
            NFT Tools
            <FaqArrow className="side-arrow" width="8" heigth="8" />
          </summary>
          <div className="browser-side-summary-content">
            <a
              href="javascript:;"
              className="tab-active"
              onClick={(e) => {
                setContentName("moulds");
                makeActive(e.target);
              }}
            >
              Moulds
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("airdrop");
                makeActive(e.target);
              }}
            >
              NFT Airdrop
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("fractonalization");
                makeActive(e.target);
              }}
            >
              Fractionalization
            </a>

            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("morphing");
                makeActive(e.target);
              }}
            >
              Morphing
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("ticketing");
                makeActive(e.target);
              }}
            >
              NFT Ticketing
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("threeNft");
                makeActive(e.target);
              }}
            >
              3D NFT & 3D NFT Moulds
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("collections");
                makeActive(e.target);
              }}
            >
              Collections
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("collages");
                makeActive(e.target);
              }}
            >
              Collages
            </a>
          </div>
        </details>

        <details className="browser-side-summary">
          <summary className="side-summary">
            Social Tools
            <FaqArrow className="side-arrow" width="8" heigth="8" />
          </summary>
          <div className="browser-side-summary-content">
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("communityDao");
                makeActive(e.target);
              }}
            >
              Community Based DAOs
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("airdrop");
                makeActive(e.target);
              }}
            >
              NFT Airdrop
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("fanTokens");
                makeActive(e.target);
              }}
            >
              Fan Tokens
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("poap");
                makeActive(e.target);
              }}
            >
              POAP NFTs
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("passports");
                makeActive(e.target);
              }}
            >
              NFT Passports
            </a>
          </div>
        </details>

        <details className="browser-side-summary">
          <summary className="side-summary">
            Metaverse Tools
            <FaqArrow className="side-arrow" width="8" heigth="8" />
          </summary>
          <div className="browser-side-summary-content">
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("customWear");
                makeActive(e.target);
              }}
            >
              Custom Wearables
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("augmented");
                makeActive(e.target);
              }}
            >
              Augmented Reality NFTs
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("avatar");
                makeActive(e.target);
              }}
            >
              Avatar Builder
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("virtualPets");
                makeActive(e.target);
              }}
            >
              Virtual Pets
            </a>
          </div>
        </details>

        <details className="browser-side-summary">
          <summary className="side-summary">
            DeFi Tools
            <FaqArrow className="side-arrow" width="8" heigth="8" />
          </summary>
          <div className="browser-side-summary-content">
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("defiCommerce");
                makeActive(e.target);
              }}
            >
              DeFi E-Commerce
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("mintToken");
                makeActive(e.target);
              }}
            >
              Mint Token
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("whiteWallet");
                makeActive(e.target);
              }}
            >
              Whitelabel Wallet
            </a>
          </div>
        </details>

        <details className="browser-side-summary">
          <summary className="side-summary">
            GameFi Tools
            <FaqArrow className="side-arrow" width="8" heigth="8" />
          </summary>
          <div className="browser-side-summary-content">
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("gameBuilder");
                makeActive(e.target);
              }}
            >
              Game Builder
            </a>
            <a
              href="javascript:;"
              className="tab"
              onClick={(e) => {
                setContentName("gameAssets");
                makeActive(e.target);
              }}
            >
              In-Game Assets
            </a>
          </div>
        </details>
      </div>
    </BrowserSide>
  );
};

const BrowserSide = styled.div`
  font-family: "Poppins", serif;
  min-width: 200px;
  position: absolute;
  left: 0;
`;

export const BrowserMobilePanel = () => {
  const [category, setCategory] = useState(0);
  const [openedIndex, setOpenedIndex] = useState(null);

  const {
    moulds,
    fractionalization,
    morphing,
    nftTicketing,
    threeNft,
    collections,
    collages,
    communityDao,
    fanTokens,
    poap,
    passports,
    customWear,
    augmented,
    avatar,
    virtualPets,
    defiCommerce,
    mintToken,
    whiteWallet,
    gameAssets,
    gameBuilder,
    nftAirdrop,
  } = browserInfo;

  const content = [
    [
      moulds,
      nftAirdrop,
      fractionalization,
      morphing,
      nftTicketing,
      threeNft,
      collections,
      collages,
    ],
    [communityDao, nftAirdrop, fanTokens, poap, passports],
    [customWear, augmented, avatar, virtualPets],
    [defiCommerce, mintToken, whiteWallet],
    [gameAssets, gameBuilder],
  ];

  const changeCategory = (i) => {
    setOpenedIndex(null);
    setCategory(i);
  };

  return (
    <BrowserMobileWrapper>
      <div className="browser-mobile-title">Anmol Tools</div>

      <div className="browser-mobile-nav">
        <div
          className={`item ${category === 0 && "active"}`}
          onClick={() => changeCategory(0)}
        >
          NFT Tools
        </div>
        <div
          className={`item ${category === 1 && "active"}`}
          onClick={() => changeCategory(1)}
        >
          Social Tools
        </div>
        <div
          className={`item ${category === 2 && "active"}`}
          onClick={() => changeCategory(2)}
        >
          Metaverse Tools
        </div>
        <div
          className={`item ${category === 3 && "active"}`}
          onClick={() => changeCategory(3)}
        >
          DeFi Tools
        </div>
        <div
          className={`item ${category === 4 && "active"}`}
          onClick={() => changeCategory(4)}
        >
          GameFi Tools
        </div>

        <div className="browser-mobile"></div>
      </div>

      <div className="browser-mobile-subs">
        {content[category].map((i, index) => {
          return (
            <BrowserMobileItem
              i={i}
              key={index}
              index={index}
              openedIndex={openedIndex}
              setOpenedIndex={setOpenedIndex}
            />
          );
        })}
      </div>
    </BrowserMobileWrapper>
  );
};

const BrowserMobileWrapper = styled.div`
  width: 100%;
  border-radius: 0 0 10px 10px;
`;

export const BrowserMobileItem = ({
  i,
  index,
  openedIndex,
  setOpenedIndex,
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  const openMenuHandler = () => {
    setOpenedIndex(index);
    if (openedIndex === index && openMenu === true) {
      return false;
    }

    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    if (openedIndex !== index) {
      setOpenMenu(false);
    }
  }, [openedIndex, setOpenMenu]);

  return (
    <div
      className={`browser-mobile-sub-item ${openMenu && "active"}`}
      onClick={() => openMenuHandler()}
    >
      <h4>{i.name}</h4>
      {i?.title ?? <h5>{i?.title}</h5>}
      {typeof i?.text === "object" ? (
        Object.values(i?.text).map((value) => {
          return <p>{value}</p>;
        })
      ) : (
        <p>{i?.text}</p>
      )}
      {i?.img ? (
        <img src={i?.img} alt="picture item" />
      ) : i?.video ? (
        <div className="responsive-video">
          <video src={i?.video} controls loop muted />
        </div>
      ) : (
        <BrowserComingSoon />
      )}
    </div>
  );
};

const BrowserPanel = () => {
  return (
    <div className="browser-panel">
      <div className="browser-address">
        <div className="browser-controls">
          <span className="red" />
          <span className="yellow" />
          <span className="green" />
        </div>

        <span>
          <img src={slideBtn} />
          <img className="browser-arrow-down" src={arrowDown} />
        </span>
        <img className="browser-arrow-left" src={browserArrowLeft} />

        <div className="browser-domain">
          <div className="address">
            <img
              style={{ marginRight: "10px", marginBottom: "8px" }}
              src={anmolLogo}
            />
            <span>anmol.network/anmoltools</span>
            <img
              src={lock}
              className="address-lock"
              style={{ marginLeft: "10px", marginBottom: "2px" }}
            />
          </div>
          <img src={more} />
        </div>
      </div>
      <img className="browser-panel-cross" src={cross} />
    </div>
  );
};

const BrowserComingSoon = () => {
  return (
    <div className="coming-wrapper">
      <span className="coming-stay">STAY TUNED</span>
      <span className="coming-soon">
        COMING
        <span style={{ marginLeft: "10px" }}>SOON!</span>
      </span>
      <button
        onClick={() => {
          window.open("https://anmolnetwork.typeform.com/features", "_blank");
        }}
        className="pill-btn px-4 py-2"
      >
        Notify Me
      </button>
    </div>
  );
};

const BrowserContent = ({ title, text, img, video }) => {
  return (
    <BrowserContentSec>
      <div className="browser-content-text text-white">
        <h5 className="mb-4">
          <strong>{title}</strong>
        </h5>
        {typeof text === "object" ? (
          Object.values(text).map((value) => {
            return <p>{value}</p>;
          })
        ) : (
          <p>{text}</p>
        )}
      </div>
      {img ? (
        <div className="browser-content-img">
          <img className="responsive-img" src={img} alt="" />
        </div>
      ) : video ? (
        <div className="browser-content-img">
          <video className="responsive-video" src={video} controls loop muted />
        </div>
      ) : (
        <BrowserComingSoon />
      )}
    </BrowserContentSec>
  );
};

const BrowserContentSec = styled.div`
  padding: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  min-height: 437px;

  .coming-wrapper {
    padding: 0;
  }
`;

const ToolPart = styled.div`
  margin-top: 148px;
  padding: 0 92px;

  @media (max-width: 480px) {
    padding: 0 20px;
  }

  & > h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 110%;
    text-transform: capitalize;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const ToolContainer = styled.div`
  background: Linear-Gradient(#fe18a6, #5761d7, #448bdd);
  margin-top: 63px;
  width: 100%;
  box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  padding: 1.5px;
  
  //@media (min-width: 1200px) {
  //  height: 537px;
  //}
  //
  //@media (min-width: 1800px) {
  //  height: 637px;
  //}
`;

const ToolBox = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background: linear-gradient(180deg, #110e4e 0%, #121053 46.06%, #491d7d 100%);
`;

const LogoBox = styled.div`
  //position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: fit-content;

  & > h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    margin-bottom: 20px;
  }

  & > img {
    width: 28vw;
    height: 20px;
    object-fit: contain;
    margin-bottom: 41px;
  }

  @media (max-width: 1200px) {
    position: static;
    margin-top: 30px;
    & > h3 {
      font-size: 18px;
    }

    & > img {
      width: 50vw;
      height: 20px;
      margin-bottom: 41px;
    }
  }

  @media (max-width: 480px) {
    margin-top: 30px;
    & > h3 {
      font-size: 14px;
    }

    & > img {
      width: 60vw;
      height: 20px;
    }
  }
`;
