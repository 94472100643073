import { Grid } from "@mui/material";
import { useState } from "react";
import data from "../jobs.json";
import { ReactComponent as JobDot } from "../Assets/jobDot.svg";
import { JobPopup } from "./popup";
import styled from "styled-components";

export function Jobs() {
  const [currentCategory, setCurrentCategory] = useState("All");
  const jobs_data = data.jobs;

  const changeFilter = (category) => {
    setCurrentCategory(category);
  };

  const jobs_data_html = jobs_data.map((i) => {
    if (i.job.category !== currentCategory) {
      if (currentCategory !== "All") {
        return false;
      }
    }

    return (
      <Grid item xs={12} lg={6} className="column">
        <Job
          title={i.job.title}
          type={i.job.type}
          mode={i.job.mode}
          location={i.job.location}
          posted={i.job.posted}
          category={i.job.category}
          currentCategory={currentCategory}
          description={i.job.description ? i.job.description : null}
          responsibilities={
            i.job.responsibilities ? i.job.responsibilities : null
          }
          qualifications={i.job.qualifications ? i.job.qualifications : null}
          preferred={i.job.preferred ? i.job.preferred : null}
          benefits={i.job.benefits ? i.job.benefits : null}
        />
      </Grid>
    );
  });

  return (
    <JobPart
      className="sbg-blue text-start text-white"
      style={{ padding: "31px 0 203px" }}
      id={"positions"}
    >
      <div className="main-p pt-5 pb-3">
        <h5>Available Positions</h5>
      </div>
      <div className="border-bottom jobs-fonts">
        <div className="w-100 main-p mb-3">
          <div
            className={`d-inline mr-3 filter-btn ${
              currentCategory === "All" && "active"
            }`}
            onClick={() => changeFilter("All")}
          >
            <p className="d-inline">All</p>
          </div>
          <div
            className={`d-inline mr-3 filter-btn ${
              currentCategory === "Engineering" && "active"
            }`}
            onClick={() => changeFilter("Engineering")}
          >
            <p className="d-inline">Engineering</p>
          </div>
          <div
            className={`d-inline mr-3 filter-btn ${
              currentCategory === "Creative" && "active"
            }`}
            onClick={() => changeFilter("Creative")}
          >
            <p className="d-inline">Creative</p>
          </div>
          <div
            className={`d-inline mr-3 filter-btn ${
              currentCategory === "Marketing" && "active"
            }`}
            onClick={() => changeFilter("Marketing")}
          >
            <p className="d-inline filter-btn">Growth&nbsp;&&nbsp;Marketing</p>
          </div>
        </div>
      </div>
      <div className="pt-5 main-p">
        <Grid container spacing={6}>
          {jobs_data_html}
        </Grid>
      </div>
    </JobPart>
  );
}

export function Job(props) {
  const {
    title,
    type,
    mode,
    location,
    posted,
    description,
    category,
    currentCategory,
    responsibilities,
    qualifications,
    preferred,
    benefits,
  } = props;

  const [isPopOpen, setIsPopOpen] = useState(false);

  const handlePopOpen = () => {
    setIsPopOpen(true);
  };

  return (
    <Border>
      <CareersPosition>
        <div
          className="col-9 p-3 d-flex align-items-center"
          style={{ fontFamily: "Poppins" }}
        >
          <div className="row">
            <p className="mb-1">{title}</p>
            <div className="career-position-details">
              <span>{type}</span>
              <JobDot />
              <span>{mode}</span>
            </div>
            <p className="font-sm">Location: {location}</p>
          </div>
        </div>
        <PositionButton
          onClick={() => handlePopOpen()}
          style={{ cursor: "pointer" }}
          className="career-position-button col-3 purp-bg d-flex flex-column justify-content-center align-items-center"
        >
          <a>
            <p
              style={{ fontFamily: "Poppins" }}
              className="font-xs d-inline px-1"
            >
              Learn More
            </p>
            <img src={require("../images/Arrow 4.png")} />
          </a>
        </PositionButton>
        <div>
          <JobPopup
            title={title}
            type={type}
            mode={mode}
            location={location}
            posted={posted}
            description={description}
            responsibilities={responsibilities}
            qualifications={qualifications}
            preferred={preferred}
            benefits={benefits}
            isPopOpen={isPopOpen}
            setIsPopOpen={setIsPopOpen}
          />
        </div>
      </CareersPosition>
    </Border>
  );
}

const JobPart = styled.div`
  h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    background: linear-gradient(to right, #448add, #fd18a6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
  }
`;

const Border = styled.div`
  background: linear-gradient(#fc1ba7 100%, #000000 0%, #40affd 100%);
  padding: 1px;
  border-radius: 10px;
  overflow: hidden;
`;

const CareersPosition = styled.div`
  background: linear-gradient(180deg, #110e4e 0%, #121053 46.06%, #491d7d 100%);
  box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.5px);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const PositionButton = styled.div`
  background: linear-gradient(90deg, #9230af 15.86%, #181058 90%);
  box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.5px);
  border-radius: 0px 10px 10px 0px;
  &:hover {
    background: linear-gradient(90deg, #ff4ff5 15.86%, #9230af 90%);
  }
`;
