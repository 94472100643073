import { Box } from "@mui/material";
import { Member } from "./members";
import styled from "styled-components";

export const Team = () => {
  return (
    <TeamSection>
      <Box flexGrow={1} className="text-white about-us-mob">
        <TitlePart>
          <h2>The Anmol Team</h2>
          <h1>The Team of the Future</h1>
          <h3>
            Anmol is made up of a diverse team of individuals, spanning from
            Pakistan, Germany,
            <br />
            Poland, Canada, the US, and the UK. Our team is heavily invested in
            the mission to bring
            <br />
            NFTs to the masses, and make NFT creation functional and easy for
            the end-user.
          </h3>
        </TitlePart>
        {/*<div className='p-5'>*/}
        {/*  <Grid container spacing={3}>*/}
        {/*    {membersInfo.map((member, key) => {*/}
        {/*      const {name, tags, designation, about, img} = member*/}
        {/*      return (*/}
        {/*        <Grid key={key} item xs={12} sm={12} md={4} lg={3}>*/}
        {/*          <Member name={name} tags={tags} designation={desgination} image={img}/>*/}
        {/*        </Grid>*/}
        {/*      )*/}
        {/*    })}*/}
        {/*  </Grid>*/}
        {/*</div>*/}

        <MembersContainer>
          {/*<div className='first-member-section'>*/}
          <MemberRow>
            <Member
              name="Furqan"
              tags={["darwinia", "polkadot", "mark", "edgeware"]}
              designation="Founder & CVO"
              image={require("../images/Furqan.png")}
              about="Our Founder and Chief Vision Officer is Furqan Ahmed. Furqan has dedicated his working life towards blockchain technology, and increasing overall adoption. He is a well-versed Substrate developer and a highly experienced member of the Polkadot space. He has worked with Darwinia, Edgeware, Subscan, and Plasm. Furqan takes a hands-on approach to this project, oftentimes working on the programming side of Anmol."
              linkedin="https://www.linkedin.com/in/furqanahmed18/"
            />

            <Member
              name="Phaedra"
              linkedin="https://www.linkedin.com/in/senerie/"
              tags={["tedx", "atnt", "bid", "covid-global"]}
              designation="Chief Marketing Officer"
              image={require("../images/Phaedra.png")}
              about="Our Chief Marketing Officer is Phaedra Poliquin. Phaedra has a multifaceted background ranging from the entertainment industry, corporate executive roles, and government campaigns. She has produced TEDx events, and was an organizer of the COVID 19 Global Hackathon. She previously worked on the DLN blockchain project.
              "
            />

            <Member
              name="Ilona"
              linkedin="https://www.linkedin.com/in/ilona-korzewska-34a7114b/"
              designation="Illustrator"
              about="Our Illustrator is Ilona Korzewska. Ilona is well-versed in design, previously being a Visual Designer at Picadilla Games, and a Graphic Designer at The Knights of Unity. She has also been a Youth Coordinator at Mouvement international ATD Quart Monde, a global human rights project."
              image={require("../images/Ilona.png")}
            />
          </MemberRow>
          <MemberRow>
            <Member
              name="Saber"
              tags={[
                { name: "github", link: "https://github.com/saberdotcoder" },
                "polkadot",
                "TON",
                "ethereum",
              ]}
              linkedin="https://www.linkedin.com/in/szafarpoor"
              designation="Tech lead"
              about="Saber is our Tech Lead. He has a background in software engineering, and a strong passion toward Web3 movement. He has worked on multiple projects on different chains, including the Polkadot, EVM, TON ecosystem. He and his team are working on the Anmol Network core and user interfaces development."
              image={require("../images/Saber.png")}
            />

            <Member
              name="Jordan"
              linkedin="https://www.linkedin.com/in/jordan-mack-b6083b1/"
              tags={["nervos"]}
              designation="Advisor"
              image={require("../images/Jordan.png")}
              about="Our advisor is a Senior Software Engineer at the Nervos Foundation. He is the founder of the RigidBit storage blockchain and has written three blockchain related whitepapers. His blockchain and tech related articles have been featured in multiple publications. He has been the lead developer of Brilliant Notion since 2013 and has been involved with cryptocurrency since 2011."
            />

            <Member
              name="Hamid"
              linkedin="https://www.linkedin.com/in/hamid-roohi-b31b731a8/"
              tags={[
                { name: "github", link: "https://github.com/hamidroohi71" },
              ]}
              designation="Frontend Developer"
              image={require("../images/hamid.png")}
              about="Hamid is a Frontend Developer. He is a senior React developer with 5+ years of experience. He has worked on multiple projects in Web3 ecosystem. He is working on the Anmol Network frontend development."
            />
          </MemberRow>

          {/*</div>*/}

          {/*<div className='second-member-section'>*/}

          {/*</div>*/}
        </MembersContainer>
      </Box>
    </TeamSection>
  );
};

const MembersContainer = styled.div`
  max-width: 90%;
  margin: 60px auto 0;
`;

const MemberRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @media (max-width: 1025px) {
    flex-direction: column;
  }

  &:first-child {
    margin-bottom: 30px;
  }
`;

const TeamSection = styled.div`
  padding: 144px 8vw 176px;
`;

const TitlePart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 31px;
  }

  & > h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 51px;
    color: #ac5aff;
    margin: 0;
  }

  & > h3 {
    font-family: "Hind";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }
`;
