import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/anmol-logo-new.svg";

import "./main.css";

export function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const openMenuHandler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light main-nav main-p border-bottom">
      <a className="navbar-brand" href="/">
        <img src={Logo} alt="Anmol" />
      </a>
      <button
        onClick={() => openMenuHandler()}
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-burger">
          <span className={`navbar-burger-lines ${isOpen && "active"}`}></span>
        </span>
      </button>
      <div
        className="collapse navbar-collapse flex-row-reverse"
        id="navbarNav"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <ul className="navbar-nav">
          <li className="nav-item active mx-3">
            <a
              className="nav-link text-white font-xs"
              href="https://github.com/anmolnetwork/"
              target="_blank"
            >
              Github
            </a>
          </li>
          <li className="nav-item mx-3">
            <a href="/team" className="nav-link text-white font-xs">
              About Us
            </a>

            <div className="my-2"></div>
          </li>
          <li className="nav-item mx-3">
            <a className="nav-link text-white font-xs" href="/careers">
              Careers
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
