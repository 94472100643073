import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import { circularProgressClasses, requirePropFactory } from "@mui/material";
import { useEffect } from "react";
import { ScrollbarProps } from "react-custom-scrollbars";
import { Scrollbars } from "react-custom-scrollbars";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export function PrivacyPolicy() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <a onClick={handleClickOpen}>Privacy Policy</a>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xl"
        className="privacy-policy"
      >
        <DialogContent
          onClose={handleClose}
          className="popup-bg text-white font-m p-0"
        >
          <div className="border-bottom p-5">
            <div className="row">
              <div className="col-11">
                <h2>Privacy Policy</h2>
              </div>
              <div className="col-1 p-0 text-end">
                <img
                  onClick={handleClose}
                  className="close-btn"
                  src={require("../images/Close.png")}
                />
              </div>
            </div>
          </div>
          <div className="bodyScroll">
            <p className="m-5">
              We believe that your business is no one else, and your privacy is
              as important to us as it is to you. Therefore, we will protect the
              information you share with us. To protect your privacy and your
              data, we follow the worldwide practices for customer privacy and
              data protection. At the outset, we assure you that:
              <ul className="pl-1 mt-3">
                <li className="mb-3">
                  We will not sell or give away your name, identity, mailing
                  address, phone number, email address or any other information
                  to anyone under any circumstances, subject to the terms of
                  this Privacy Policy.
                </li>
                <li>
                  We will use state-of-the-art security measures to protect your
                  information from unauthorized use.
                </li>
              </ul>
            </p>

            <div className="p-5">
              <h4>
                <strong>NOTICE</strong>
              </h4>
              <p className="mt-3">
                We will ask you when we need your personal information, either
                to identify you, or to allow us to contact you. Generally, this
                information is requested when you create a Registration ID on
                our website, enter a contest, order email newsletters or join a
                limited-access premium website. We use your personal information
                for the following primary purposes:
              </p>
              <ul className="pl-1">
                <li className="mb-1">
                  To make the site easier for you to use by not having to enter
                  information again and again;
                </li>
                <li className="mb-1">
                  To help you quickly find software, services or information
                  which suit your needs;
                </li>
                <li className="mb-1">
                  To help us provide you with the relevant content and data; and
                </li>
                <li className="mb-1">
                  To alert you to product upgrades, special offers, updated
                  information and other new services from us.
                </li>
              </ul>
            </div>
            <div className="px-5">
              <h4>
                <strong>CONSENT</strong>
              </h4>
              <p className="mt-3">
                If you choose not to register or provide personal information,
                you can still use most of [insert name of the website]. However,
                there will be some areas which would not be accessible to you
                for the want of registration. If you decide to register, you
                will be able to select the kinds of information you want to
                receive from us by subscribing to various services, for instance
                our electronic newsletters. If you do not want us to communicate
                with you about offers regarding our products, programs, events,
                or services by email, postal mail, or telephone, you may select
                the option at the time of registration, stating that you do not
                wish to receive marketing messages from us.
              </p>
              <p>
                We occasionally allow other businesses to offer our registered
                customers information about their products and services, using
                postal mail only. If you do not want to receive these offers,
                you may select the option, stating that you do not wish to
                receive marketing materials from third parties.
              </p>
            </div>
            <div className="px-5 mt-5">
              <h4>
                <strong>ACCESS</strong>
              </h4>
              <p className="mt-3">
                We will provide you with the means to ensure that your personal
                information is correct and current. You may review and update
                this information at any time at the Visitor Center. There, you
                can:
              </p>
              <ul className="pl-1">
                <li className="mb-1">
                  View and edit personal information you have already provided
                  us with.
                </li>
                <li className="mb-1">
                  Alter your preferences regarding receiving of marketing
                  information from us, or third parties.
                </li>
                <li className="mb-1">
                  Subscribe or unsubscribe for electronic newsletters about our
                  services and products. Once you register, you will not need to
                  do it again. Wherever you go on our website, your information
                  stays with you.
                </li>
              </ul>
            </div>
            <div className="px-5 mt-5">
              <h4>
                <strong>SECURITY</strong>
              </h4>
              <p className="mt-3">
                We have taken strong measures to protect the security of your
                personal information and to protect your data from loss, misuse,
                unauthorized access or disclosure, alteration, or destruction.
              </p>
              <p>
                We guarantee your electronic transactions, financial or
                otherwise, to be 100% safe and secure. When you place orders or
                access your personal account information, you're utilizing
                secure server software SSL, which encrypts your personal
                information before it is sent over the Internet. SSL is one of
                the safest encryption technologies available.
              </p>
              <p>
                Your personal information is never shared with any third party
                without your permission, except under conditions explained
                above, and is stored in password-controlled servers with limited
                access. Your information may be stored and processed in [insert
                name of the country] or any other country where we, our
                subsidiaries, affiliates or agents are located.
              </p>
              <p>
                You also have a significant role in protecting your information.
                No one can see or edit your personal information without knowing
                your user name and password, so do not share this information
                with others.
              </p>
            </div>
            <div className="px-5 mt-5">
              <h4>
                <strong>NOTICE TO PARENTS</strong>
              </h4>
              <p className="mt-3">
                Parents or guardians: we want to help you guard your children's
                privacy. We encourage you to talk to your children about safe
                and responsible use of their Personal Information while using
                the Internet.
              </p>
            </div>
            <div className="px-5 mt-5">
              <h4>
                <strong>ENFORCEMENT</strong>
              </h4>
              <p className="mt-3">
                If for some reason you believe that we have not adhered to the
                principles enumerated in this Privacy Policy, please notify us
                by emailing us on [insert email address], and we will do our
                best to determine and rectify the problem promptly. Please
                ensure that the subject line of your email reads: “PRIVACY
                POLICY”.
              </p>
            </div>
            <div className="px-5 mt-5">
              <h4>
                <strong>ELECTRONIC PRODUCT REGISTRATION</strong>
              </h4>
              <p className="mt-3">
                When you buy and install a new product, we may ask you to
                register your purchase electronically. When you do, we merge
                your registration information with your personal profile, if you
                have created one. If you haven't previously registered with us,
                we create a personal profile for you from your product
                registration information. If you ever want to review or update
                that information, you can visit the Profile Center, click on
                Update Profile, and edit any of the Personal Information in your
                profile. If you haven't already created a Registration ID, we
                will ask you to do so. This ensures that only you can access
                your information.
              </p>
            </div>
            <div className="px-5 mt-5">
              <h4>
                <strong>CUSTOMER PROFILES</strong>
              </h4>
              <p className="mt-3">
                As mentioned above, every registered customer has a unique
                personal profile. Each profile is assigned a unique personal
                identification number, which helps us ensure that only you can
                access your profile.
              </p>
              <p>
                When you register, we create your profile, assign a personal
                identification number, and then send this personal
                identification number back to your hard drive in the form of a
                cookie, which is a very small bit of code. This code is uniquely
                yours. It is your passport to seamless travel across our
                website, allowing you to download free software, order free
                newsletters, and visit premium sites without having to fill out
                registration forms with information you've already provided.
                Even if you switch computers, you will not have to re-register —
                just use your Registration ID to identify yourself.
              </p>
            </div>
            <div className="px-5 mt-5">
              <h4>
                <strong>WHAT WE DO WITH THE INFORMATION YOU SHARE</strong>
              </h4>
              <p className="mt-3">
                When you join us, you provide us with your contact information,
                including your name and email address. We use this information
                to send you updates about your order, questionnaires to measure
                your satisfaction with our service and announcements about new
                and exciting services that we offer. When you order from us, we
                ask for your credit card number and billing address. We use this
                information only to bill you for the product(s) you order at
                that time. For your convenience, we do save billing information
                in case you want to order from us again, but we do not use this
                information again without your permission.
              </p>
              <p>
                We occasionally hire other businesses to provide limited
                services on our behalf, including packaging, mailing and
                delivering purchases, answering customer questions about
                products or services, sending postal mail and processing event
                registration. We will only provide those businesses with the
                information they need to deliver the service, and ensure that
                this information is not used by such businesses for any other
                purposes through execution of bonds and agreements to this
                effect.
              </p>
              <p>
                We will disclose your personal information, without notice, only
                if required to do so by law or in the good faith belief that
                such action is necessary to: (a) conform to the edicts of the
                law or comply with legal process served on us or our website;
                (b) protect and defend our rights or property; and (c) act in
                urgent circumstances to protect the personal safety of our
                users, or the general public.
              </p>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export function TermsAndConditions() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <a onClick={handleClickOpen}>Terms & Conditions</a>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        cssClass="bootstrap-dialog"
        maxWidth="md"
        className="terms-conditions"
      >
        <DialogContent
          onClose={handleClose}
          className=" popup-bg text-white font-m p-0"
        >
          <div className="border-bottom p-5">
            <div className="row">
              <div className="col-11">
                <h2>APP LICENSE AGREEMENT</h2>
              </div>
              <div className="col-1 p-0 text-end">
                <img
                  onClick={handleClose}
                  className="close-btn"
                  src={require("../images/Close.png")}
                />
              </div>
            </div>
          </div>
          <div className="bodyScroll">
            <p className="m-5">
              PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY CLICKING
              “ACCEPTED AND AGREED TO,” YOU AGREE TO THESE TERMS AND CONDITIONS.
            </p>
            <p className="mx-5">
              This App License and System Subscription Agreement (this
              “Agreement”) is a legally binding contract between Anmol Network
              (“Provider”) and an individual end user of Provider’s System, as
              defined below (“You,” “Your”). Provider provides Moulds App (the
              “System”). You agree that when you access or use the System, You
              will do so subject to this Agreement.{" "}
              <strong>
                DO NOT ACCESS OR USE THE SYSTEM IF YOU ARE UNWILLING OR UNABLE
                TO BE BOUND BY THIS AGREEMENT.
              </strong>
            </p>
            <p className="mx-5">
              This Agreement is effective as of the date You click “Accepted and
              Agreed To” (the “Effective Date”).
            </p>

            <div className="p-5">
              <h5 className="font-nm">
                <strong>1. USE OF THE SYSTEM IN GENERAL.</strong>
              </h5>

              <ul className="pl-2 my-3">
                <li className="mb-2">
                  <u>Eligibility.</u> You represent and warrant that You are 18
                  years old or older, and You recognize and agree that You must
                  be 18 years old or older to use the System.
                </li>
                <li className="mb-2">
                  <u>System Subscription.</u> During the Term (as defined in
                  Section 10.1 below), You may access and use the System. You
                  may reproduce and use Provider's standard manual related to
                  use of the System (the “Manual”) solely as necessary to
                  support use of the System.
                </li>
                <li className="mb-2">
                  <u>System Revisions.</u> Provider may revise the features and
                  functions of the System at any time.
                </li>
                {/* <li className="mb-2">
                <u>Subscription Fees.</u> You agree to pay Provider the fee set
                forth in Your order on the dates required therein. Provider will
                not be required to refund fees under any circumstances.
              </li> */}
              </ul>
            </div>
            <div className="px-5">
              <h5>
                <strong>2. THE APP</strong>
              </h5>

              <ul className="pl-2 my-3">
                <li className="mb-3">
                  <u>License.</u> Provider hereby grants You a nonexclusive
                  license to reproduce and use one copy of the App (as defined
                  below) on Your mobile device, solely as a component of the
                  System, provided You comply with the restrictions set forth
                  below in Section 2.2 (Restrictions on Software Rights). The
                  license in the preceding sentence does not include use by any
                  third party, and You shall not permit any such use. Provider
                  grants the license in this Section 2.1 under copyright and,
                  solely to the extent necessary to exercise such rights, under
                  any other applicable intellectual property rights. (The “App”
                  means Provider’s Moulds App. The App is a component of the
                  System and is included in references thereto, except in
                  provisions that separately address the App.)
                </li>
                <li className="mb-3">
                  <u>Restrictions on Software Rights.</u> Copies of the App
                  created or transferred pursuant to this Agreement are
                  licensed, not sold, and You receive no title to or ownership
                  of any copy or of the App itself. Furthermore, You receive no
                  rights to the App other than those specifically granted in
                  Section 2.1 above. Without limiting the generality of the
                  foregoing, You shall not: (a) modify, create derivative works
                  from, distribute, publicly display, publicly perform, or
                  sublicense the App; (b) use the App in any way forbidden by
                  Section 5.1 below; or (c) reverse engineer, decompile,
                  disassemble, or otherwise attempt to derive any of the App’s
                  source code.
                </li>
              </ul>
            </div>

            <div className="px-5 mt-5">
              <h5>
                <strong>3. YOUR CONTENT</strong>
              </h5>

              <ul className="pl-2 my-3">
                <li className="mb-3">
                  <u>Permission from You.</u> You grant Provider permission to
                  access, process, and otherwise use Your Content (as defined
                  below) in order to provide Provider’s products and/or services
                  to You, to track and analyze Your use of the System, and make
                  Your Content available to other users of the System and other
                  third parties. To the extent that You have intellectual
                  property rights in Your Content, You grant Provider a
                  world-wide, perpetual, non-exclusive, royalty-free,
                  sublicensable, transferable license to use and prepare
                  derivative works from Your Content for the purposes outlined
                  in this Agreement. You agree that Your Content is not any
                  person’s or entity’s confidential information, including
                  Yours. As between the parties, You retain ownership of Your
                  Content. (“Your Content” means any Content transmitted by You
                  or on Your behalf to Provider or its agents. “Content” means
                  text, images, photos, audio or video files, and other forms of
                  data or communication.)
                </li>
                <li className="mb-3">
                  <u>Rights in Your Content.</u> You represent and warrant that
                  You own Your Content or have received a valid license to Your
                  Content and that submitting or transmitting Your Content to or
                  through the System will not violate the rights of any third
                  party, including without limitation intellectual property,
                  privacy, or publicity rights. Provider is under no obligation
                  to review or screen Your Content or other System users’
                  Content.
                </li>
                <li className="mb-3">
                  <u>Accuracy.</u> Provider has no responsibility or liability
                  for the accuracy of any Content submitted to or transmitted
                  through the System by You or another user, including without
                  limitation Your Content.
                </li>
                <li className="mb-3">
                  <u>Right to Retain, Delete or Suspend Access.</u> You must not
                  rely on the System for backup or storage of Your Content.
                  Provider may retain Your Content even if You are no longer
                  using the System, but Provider is not required to give You
                  copies of Your Content. Provider may permanently delete or
                  erase Your Content or suspend Your access to Your Content
                  through the System at any time and for any reason.
                </li>
              </ul>
            </div>
            <div className="px-5 mt-5">
              <h5>
                <strong>4. PRIVACY</strong>
              </h5>

              <ul className="pl-2 my-3">
                <li className="mb-3">
                  <u>Privacy Policy & Compliance.</u> You acknowledge Provider’s
                  privacy policy at{" "}
                  <a href="http://www.anmol.network">
                    http://www.anmol.network
                  </a>
                  , and you recognize If and agree that nothing in this
                  Agreement restricts Provider’s right to alter such privacy
                  policy. If Provider receives a “right to know,” deletion,
                  “right to be forgotten,” or similar request related to Your
                  Content, Provider may respond in accordance with applicable
                  law. Nothing in this Agreement precludes Provider from
                  asserting rights or defenses it may have under applicable law
                  related to such requests.
                </li>
                <li className="mb-3">
                  <u>De-Identified Data.</u> Provider may use, reproduce, sell,
                  publicize, or otherwise exploit DeIdentified Data (as defined
                  below) in any way, in its sole discretion, including without
                  limitation aggregated with data from other customers.
                  (“De-Identified Data” refers to Your Content with the
                  following removed: information that identifies or could
                  reasonably be used to identify You, an individual person, or a
                  household.)
                </li>
                <li className="mb-3">
                  <u>Risk of Exposure.</u>{" "}
                  <strong>
                    YOU UNDERSTAND AND AGREE THAT SHARING CONTENT ONLINE
                    INVOLVES RISKS OF UNAUTHORIZED DISCLOSURE OR EXPOSURE AND
                    THAT, IN SUBMITTING YOUR CONTENT TO OR TRANSMITTING IT
                    THROUGH THE SYSTEM, YOU ASSUME THOSE RISKS.
                  </strong>{" "}
                  Provider offers no representation, warranty, or guarantee that
                  Your Content will not be exposed or disclosed through the
                  System or through errors or the actions of third parties.
                </li>
              </ul>
            </div>
            <div className="px-5 mt-5">
              <h5>
                <strong>5. YOUR RESPONSIBILITIES & RESTRICTIONS</strong>
              </h5>

              <ul className="pl-2 my-3">
                <li className="mb-3">
                  <u>Acceptable Use.</u> You agree that You shall not: (a)
                  provide System passwords or other login information to any
                  third party;
                  <br />
                  (b) share non-public System features or Content with any third
                  <br />
                  party; (c) access the System in order to build a competitive
                  product or service, to build a product using similar ideas,
                  features, functions or graphics of the System, or to copy any
                  ideas, features, functions or graphics of the System; or{" "}
                  <br />
                  (d) engage in web scraping or data scraping on or related to
                  the System, including without limitation collection of
                  information through any software that simulates human activity
                  or any bot or web crawler. If Provider suspects that You have
                  violated the requirements of this Subsection 5.1, Provider may
                  suspend Your access to the System without advanced notice, in
                  addition to such other remedies as Provider may have. Provider
                  is not obligated to take any action against You or any other
                  System user or other third party for violating this Agreement,
                  but Provider is free to take any such action it sees fit.
                </li>
                <li className="mb-3">
                  <u>Unauthorized Access.</u> You agree to take reasonable steps
                  to prevent unauthorized access to the System, including by
                  protecting Your passwords and other log-in information. You
                  shall notify Provider immediately if You know of or suspect
                  unauthorized use of the System or breach of its security.
                </li>
                <li className="mb-3">
                  <u>Compliance with Laws.</u> In using the System, You shall
                  comply with all applicable laws.
                </li>
                <li className="mb-3">
                  <u>System Access.</u> You are responsible and liable for: (a)
                  Your use of the System, including unauthorized conduct through
                  Your account and conduct that would violate the AUP or the
                  requirements of this Agreement; and (b) any use of the System
                  through Your account or passwords, whether authorized or not.
                </li>
                <li className="mb-3">
                  <u>Communications from Provider.</u> You consent to receive
                  email and/or text messages from Provider in connection with
                  Your use of the System. Standard text messaging charges
                  required by Your mobile carrier will apply to text messages
                  Provider send You.
                </li>
              </ul>
            </div>
            <div className="px-5 mt-5">
              <h5>
                <strong>6. IP & FEEDBACK</strong>
              </h5>

              <ul className="pl-2 my-3">
                <li className="mb-3">
                  <u>IP Rights in the System.</u> Provider retains all right,
                  title, and interest in and to the System, including without
                  limitation the App and all other all software used to provide
                  the System and all graphics, user interfaces, logos, and
                  trademarks reproduced through the System, as well as all
                  Content other than Your Content. This Agreement does not grant
                  You any intellectual property license or rights in or to the
                  System or any of its components, except to the limited extent
                  that this Agreement specifically sets forth Your license
                  rights to the App or the Manual. You recognize that the System
                  and its components are protected by copyright and other laws.
                </li>
                <li className="mb-3">
                  <u>Feedback.</u> Provider has not agreed to and does not agree
                  to treat as confidential any Feedback (as defined below) that
                  You provide to Provider, and nothing in this Agreement or in
                  the parties’ dealings arising out of or related to this
                  Agreement will restrict Provider’s right to use, profit from,
                  disclose, publish, keep secret, or otherwise exploit Feedback,
                  without compensating or crediting You. You hereby grant
                  Provider a perpetual, irrevocable right and license to exploit
                  Feedback in any and every way. (“Feedback” refers to any
                  suggestion or idea for improving or otherwise modifying any of
                  Provider’s products or services.)
                </li>
              </ul>
            </div>
            <div className="px-5 mt-5">
              <h5>
                <strong>7.DISCLAIMERS</strong>
              </h5>

              <ul className="pl-2 my-3">
                <li className="mb-3">
                  <u>Warranty Disclaimers.</u>YOU AGREE THAT YOU ACCEPT THE
                  SYSTEM “AS IS” AND AS AVAILABLE, WITH NO REPRESENTATION OR
                  WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT
                  LIMITATION IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                  A PARTICULAR PURPOSE, OR NONINFRINGEMENT OF INTELLECTUAL
                  PROPERTY RIGHTS, OR ANY IMPLIED WARRANTY ARISING FROM STATUTE,
                  COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE.
                  WITHOUT LIMITING THE GENERALITY OF THE FOREGOING: (a) PROVIDER
                  HAS NO OBLIGATION TO INDEMNIFY OR DEFEND YOU AGAINST CLAIMS
                  RELATED TO INFRINGEMENT OF INTELLECTUAL PROPERTY; (b) PROVIDER
                  DOES NOT REPRESENT OR WARRANT THAT THE SYSTEM WILL PERFORM
                  WITHOUT INTERRUPTION OR ERROR; (c) PROVIDER DOES NOT REPRESENT
                  OR WARRANT THAT THE SYSTEM IS SECURE FROM HACKING OR OTHER
                  UNAUTHORIZED INTRUSION OR THAT YOUR CONTENT WILL REMAIN
                  PRIVATE OR SECURE; AND (d) PROVIDER DISCLAIMS ANY
                  REPRESENTATION OR WARRANTY CONCERNING PRODUCTS OR SERVICES
                  PROVIDED BY OTHER USERS OF THE SYSTEM OR OTHER THIRD PARTIES.
                </li>
                <li className="mb-3">
                  <u>Interactions with Other Users.</u> You agree that You are
                  solely responsible for Your transactions or other
                  interactions, either through the System or through other means
                  of communication, with other users of the System. You
                  acknowledge that that Provider has no liability for any such
                  interactions. Provider may monitor or become involved in
                  disputes between You and other users of the System but has no
                  obligation to do so.
                </li>
                <li className="mb-3">
                  <u>Third Party Sites and Content.</u> You understand that the
                  System may contain or send You links to third party websites,
                  applications or features not owned or controlled by Provider
                  (“Third Party Sites”), and that links to Third Party Sites may
                  also appear in Content available to You through the System.
                  The System may also enable interaction between the System and
                  a Third Party Site through applications that connect the
                  System, or Your profile on the System, with a Third Party
                  Site. Through Third Party Sites You may be able to access
                  Content from third parties that Provider does not control
                  and/or share Your Content with others.
                  <strong>
                    YOU ACCESS THIRD PARTY SITES ENTIRELY AT YOUR OWN RISK, AND
                    PROVIDER WILL HAVE NO LIABILITY FOR YOUR USE OF OR ACCESS TO
                    THIRD PARTY SITES AND/OR THIRD PARTY CONTENT.)
                  </strong>
                </li>
              </ul>
            </div>
            <div className="px-5 mt-5">
              <h5>
                <strong>8. INDEMNIFICATION</strong>
              </h5>
              <p className="mt-3">
                You agree to defend, indemnify, and hold harmless Provider and
                the Provider Associates (as defined below) against any
                “Indemnified Claim,” meaning any third party claim, suit, or
                proceeding arising out of, related to, or alleging:
                <br /> (a) infringement or violation of third party intellectual
                property, privacy or publicity rights by Content submitted to or
                transmitted through the System from Your account, including
                without limitation by Your Content; and <br />
                (b) claims that use of the System through Your account harasses,
                defames, or defrauds a third party, infringes or misappropriates
                copyright, trade secret, or other intellectual property rights,
                or violates any law or restriction on electronic advertising.
                Your obligations set forth in this Article 8 include retention
                and payment of attorneys and payment of court costs, as well as
                settlement at Your expense and payment of judgments. Provider
                will have the right, not to be exercised unreasonably, to reject
                any settlement or compromise that requires that it admit
                wrongdoing or liability or subjects it to any ongoing
                affirmative obligations. <br />
                (The “Provider Associates” are Provider’s officers, directors,
                shareholders, parents, subsidiaries, agents, successors, and
                assigns.)
              </p>
            </div>
            <div className="px-5 mt-5">
              <h5>
                <strong>9. LIMITATION OF LIABILITY</strong>
              </h5>

              <ul className="pl-2 my-3">
                <li className="mb-3">
                  <u>Dollar Cap.</u> PROVIDER’S CUMULATIVE LIABILTY FOR ALL
                  CLAIMS ARISING OUT OF OR RELATED TO THIS AGREEMENT WILL NOT
                  EXCEED $500.
                </li>
                <li className="mb-3">
                  <u>Excluded Damages.</u> IN NO EVENT WILL PROVIDER BE LIABLE
                  FOR LOST PROFITS OR LOSS OF BUSINESS OR FOR ANY CONSEQUENTIAL,
                  INDIRECT, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES ARISING OUT
                  OF OR RELATED TO THIS AGREEMENT.
                </li>
                <li className="mb-3">
                  <u>Clarifications & Disclaimers.</u> THE LIABILITIES LIMITED
                  BY THIS ARTICLE 9 APPLY TO THE BENEFIT OF PROVIDER’S OFFICERS,
                  DIRECTORS, EMPLOYEES, AGENTS, AND THIRD PARTY CONTRACTORS, AS
                  WELL AS: (a) TO LIABILITY FOR NEGLIGENCE; (b) REGARDLESS OF
                  THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, STRICT PRODUCT
                  LIABILITY, OR OTHERWISE; (c) EVEN IF PROVIDER IS ADVISED IN
                  ADVANCE OF THE POSSIBILITY OF THE DAMAGES IN QUESTION AND EVEN
                  IF SUCH DAMAGES WERE FORESEEABLE; AND (d) EVEN IF YOUR
                  REMEDIES FAIL OF THEIR ESSENTIAL PURPOSE. You acknowledge and
                  agree that Provider has based its pricing on and entered into
                  this Agreement in reliance upon the limitations of liability
                  and disclaimers of warranties and damages in this Article 9
                  and elsewhere in this Agreement and that such terms form an
                  essential basis of the bargain between the parties. If
                  applicable law limits the application of the provisions of
                  this Article 9, Provider’s liability will be limited to the
                  maximum extent permissible.
                </li>
              </ul>
            </div>
            <div className="px-5 mt-5">
              <h5>
                <strong>10. TERM & TERMINATION</strong>
              </h5>
              <ul className="pl-2 my-3">
                <li className="mb-3">
                  <u>Term.</u> The term of this Agreement (the “Term”) will
                  commence on the Effective Date and continue until terminated
                  by either You or Provider.
                </li>
                <li className="mb-3">
                  <u>Termination.</u> Either party may terminate this Agreement
                  for any reason at any time. You may terminate this Agreement
                  by closing Your account. Provider may terminate this Agreement
                  by notifying You in writing, including without limitation via
                  text to Your mobile device.
                </li>
                <li className="mb-3">
                  <u>Effects of Termination.</u> Upon termination of this
                  Agreement, You shall cease all use of the System. The
                  following provisions will survive termination of this
                  Agreement: Articles 6 (IP & Feedback), 7 (Disclaimers), 8
                  (Indemnification), 9 (Limitation of Liability), and 11
                  (Miscellaneous); and any other provision of this Agreement
                  that must survive to fulfill its essential purpose.
                </li>
              </ul>
            </div>
            <div className="px-5 mt-5">
              <h5>
                <strong>11. MISCELLANEOUS</strong>
              </h5>

              <ul className="pl-2 my-3">
                <li className="mb-3">
                  <u>Independent Contractors.</u> The parties are independent
                  contractors. Neither party is the agent of the other, and
                  neither may make commitments on the other’s behalf.
                </li>
                <li className="mb-3">
                  <u>Notices & Infringement.</u> Provider may send notices to
                  You by email or by text to Your mobile device at the email
                  address or mobile number You provided, and such notices will
                  be deemed received 24 hours after they are sent. You may send
                  notices pursuant to this Agreement to Provider by email to
                  <a href="mailto:hello@anmol.network">
                    {" "}
                    <strong>hello@anmol.network</strong>
                  </a>{" "}
                  , and such notices will be deemed received 72 hours after they
                  are sent.
                </li>
                <li className="mb-3">
                  <u>Assignment & Successors.</u> You may not assign this
                  Agreement or any of Your rights or obligations under this
                  Agreement without Provider’s express written consent. Except
                  to the extent forbidden in this Section 11.3, this Agreement
                  will be binding upon and inure to the benefit of the parties’
                  respective successors and assigns.
                </li>
                <li className="mb-3">
                  <u>Severability.</u> To the extent permitted by applicable
                  law, the parties hereby waive any provision of law that would
                  render any clause of this Agreement invalid or otherwise
                  unenforceable in any respect. In the event that a provision of
                  this Agreement is held to be invalid or otherwise
                  unenforceable, such provision will be interpreted to fulfill
                  its intended purpose to the maximum extent permitted by
                  applicable law, and the remaining provisions of this Agreement
                  will continue in full force and effect.
                </li>
                <li className="mb-3">
                  <u>No Waiver.</u> Neither party will be deemed to have waived
                  any of its rights under this Agreement by lapse of time or by
                  any statement or representation other than by an authorized
                  representative in an explicit written waiver. No waiver of a
                  breach of this Agreement will constitute a waiver of any other
                  breach of this Agreement.
                </li>
                <li className="mb-3">
                  <u>Choice of Law & Jurisdiction:</u>This Agreement will be
                  governed solely by the internal laws of the British Virgin
                  Islands. The Provider will its best efforts to resolve any
                  potential disputes through informal, good faith negotiations.
                  If a potential dispute arises, You must contact us by sending
                  an email to{" "}
                  <a href="mailto:hello@anmol.network">
                    {" "}
                    <strong>hello@anmol.network</strong>
                  </a>{" "}
                  so that the Parties can attempt to resolve it without
                  resorting to formal dispute resolution. If the Parties aren't
                  able to reach an informal resolution within sixty days of Your
                  email, then You and the Provider both agree to resolve the
                  potential dispute by binding arbitration. The arbitration
                  shall be held on a confidential basis before a single
                  arbitrator. The arbitration will be held in the British Virgin
                  Islands unless the Parties agree to hold it elsewhere. Unless
                  agreed otherwise, the arbitrator may not consolidate Your
                  claims with those of any other party. Any judgment on the
                  award rendered by the arbitrator may be entered in any court
                  of competent jurisdiction.
                </li>
                <li className="mb-3">
                  <u>Force Majeure.</u> No delay, failure, or default, other
                  than a failure to pay fees when due, will constitute a breach
                  of this Agreement to the extent caused by epidemics, acts of
                  war, terrorism, hurricanes, earthquakes, other acts of God or
                  of nature, strikes or other labor disputes, riots or other
                  acts of civil disorder, embargoes, government orders
                  responding to any of the foregoing, or other causes beyond the
                  performing party’s reasonable control.
                </li>
                <li className="mb-3">
                  <u>Entire Agreement.</u> This Agreement sets forth the entire
                  agreement of the parties and supersedes all prior or
                  contemporaneous writings, negotiations, and discussions with
                  respect to its subject matter. Neither party has relied upon
                  any such prior or contemporaneous communications.
                </li>
                <li className="mb-3">
                  <u>Amendment.</u> Provider may amend this Agreement from time
                  to time by posting an amended version at its website and
                  sending You written notice thereof. Such amendment will be
                  deemed accepted and become effective 30 days after such notice
                  (the “Proposed Amendment Date”) unless You first give Provider
                  written notice of rejection of the amendment. In the event of
                  such rejection, this Agreement will continue under its
                  original provisions for 30 days following the Proposed
                  Amendment Date (unless either You or Provider first terminates
                  this Agreement pursuant to Article 10, Term & Termination).
                  Your continued use of the System following the effective date
                  of an amendment will confirm Your consent to the Amendment.
                  This Agreement may not be amended in any other way except
                  through a written agreement by authorized representatives of
                  each party. Notwithstanding the foregoing provisions of this
                  Section 11.9, Provider may revise the AUP at any time by
                  posting a new version at Provider’s website, and such new
                  version will become effective on the date it is posted;
                  provided if such amendment materially reduces Your rights or
                  protections, notice and consent will be subject to the
                  requirements above in this Section 11.9.
                </li>
                <li className="mb-3">
                  <u>Dispute Resolution.</u> Any legal disputes or claims
                  arising out of or related to this Agreement (including without
                  limitation claims related to the use of the System, the
                  interpretation, enforceability, revocability, or validity of
                  the Agreement, or the arbitrability of any dispute), that
                  cannot be resolved informally shall be submitted to binding
                  arbitration. You and Provider. Any judgment on the award
                  rendered by the arbitrator may be entered in any court having
                  jurisdiction thereof. Claims must be brought within the
                  statute of limitations or other time required by applicable
                  law. You agree that You shall bring any claim, action or
                  proceeding arising out of or related to the Agreement in Your
                  individual capacity, and not as a plaintiff or class member in
                  any purported class, collective, or representative proceeding.
                  The arbitrator may not consolidate the claims of more than one
                  person and may not otherwise preside over any form of a
                  representative, collective, or class proceeding. YOU
                  ACKNOWLEDGE AND AGREE THAT YOU AND PROVIDER ARE EACH WAIVING
                  THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE AS A PLAINTIFF
                  OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR
                  REPRESENTATIVE PROCEEDING IN ANY FORUM, INCLUDING WITHOUT
                  LIMITATION CLASS-WIDE ARBITRATION AND PRIVATE ATTORNEY-GENERAL
                  ACTIONS.
                </li>
              </ul>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export function MemberDesc(props) {
  const {
    name,
    designation,
    img,
    about,
    tags,
    anchorEl,
    handlePopoverClose,
    linkedin,
  } = props;

  let open = Boolean(anchorEl);

  const logos = [
    "darwinia",
    require("../images/Darwinia Logo.png"),
    "polkadot",
    require("../images/Polkadot.png"),
    "mark",
    require("../images/brand-logo-mark 1.png"),
    "edgeware",
    require("../images/edgeware-solid-main-logomark 1.png"),
    "tedx",
    require("../images/image 131.png"),
    "atnt",
    require("../images/image 132.png"),
    "bid",
    require("../images/burn it down.png"),
    "covid-global",
    require("../images/full_width 1.png"),
    "nervos",
    require("../images/download 1.png"),
    "github",
    require("../images/github-mark.png"),
  ];

  const l = logos.length;
  const tags_html = tags
    ? tags.map((tag, index) => {
        for (let i = 0; i < l; i++) {
          if (logos[i] === tag || logos[i] === tag?.name) {
            return (
              <div className="mx-1 m-1" key={index}>
                {typeof tag === "object" ? (
                  <img
                    onClick={() => {
                      window.open(tag?.link, "_blank");
                    }}
                    src={logos[i + 1]}
                    className="career_icon"
                  />
                ) : (
                  <img src={logos[i + 1]} className="career_icon" />
                )}
              </div>
            );
          }
        }
      })
    : null;

  return (
    <div>
      <Popover
        id={"simple-popover"}
        className="MemberPopover"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        transformOrigin={{ horizontal: 20, vertical: 290 }}
      >
        <div
          className="popup-bg text-white member-popup"
          style={{
            background:
              "linear-gradient(89.62deg, #9230AF -103.02%, #181058 -6.57%)",
            borderRadius: "10px",
          }}
        >
          <div className="row member-name">
            <div className="col-2 d-flex justify-content-center align-items-center">
              <div>
                <img src={img} className="m-auto border popup-member" />
              </div>
            </div>
            <div className="col-8 d-flex flex-column justify-content-center">
              <div>
                <p className="font-m m-0">{designation}</p>
              </div>
              <div>
                <p className="font-md m-0">
                  <strong>{name}</strong>
                </p>
              </div>
            </div>
            <div className="col-2 text-end">
              <img
                onClick={() => handlePopoverClose()}
                className="close-btn"
                src={require("../images/Close.png")}
              />
            </div>
          </div>
          <div className="py-3">
            <p className="mb-2 mt-3">
              <strong>About</strong>
            </p>
            <p className="font-sm">{about}</p>
          </div>
          <div className="member-tags-container">
            <div className="linked">
              <a href={linkedin} target="_blank">
                <img src={require("../images/LinkedIn.png")} />
              </a>
            </div>
            <div className="member-tags">{tags_html}</div>
          </div>
        </div>
      </Popover>
    </div>
  );
}

export function JobPopup(props) {
  const {
    title,
    type,
    mode,
    location,
    posted,
    description,
    responsibilities,
    qualifications,
    preferred,
    benefits,
    isPopOpen,
    setIsPopOpen,
  } = props;

  const handleClose = () => {
    setIsPopOpen(false);
  };

  const responsibilities_list = responsibilities
    ? responsibilities.map((responsibility) => {
        return <li className="mb-2">{responsibility}</li>;
      })
    : null;
  const qualifications_list = qualifications
    ? qualifications.map((i) => {
        return <li className="mb-2">{i}</li>;
      })
    : null;
  const preferred_list = preferred
    ? preferred.map((i) => {
        return <li className="mb-2">{i}</li>;
      })
    : null;
  const benefits_list = benefits
    ? benefits.map((i) => {
        return <li className="mb-2">{i}</li>;
      })
    : null;
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        cssClass="bootstrap-dialog"
        open={isPopOpen}
        maxWidth="md"
      >
        <DialogContent
          onClose={handleClose}
          className="popup-bg text-white font-m p-0 job-popup-container"
          style={{ overflowX: "hidden" }}
        >
          <div className="border-bottom job-position-mobile-wrap job-position-desk">
            <div className="row">
              <div className="col-11 job-title-mob">
                <h2>{title}</h2>
              </div>
              <div className="col-1 p-0 text-end">
                <img
                  onClick={handleClose}
                  className="close-btn"
                  src={require("../images/Close.png")}
                />
              </div>
            </div>

            <p className="job-position-description">Job Posted: {posted}</p>
            <p className="job-position-description">Location: {location}</p>
            <div style={{ paddingTop: "30px" }}>
              <button className="pill-btn" style={{ padding: "11px 27px" }}>
                <strong>Apply Now</strong>
              </button>
            </div>
          </div>
          <div className="bodyScroll2">
            <Scrollbars>
              <div className="p-5 job-position-mobile-p">
                <h5>
                  <>What we're looking for</>
                </h5>

                <p>{description}</p>
              </div>
              <div className="px-5 job-position-mobile-p">
                <h5>
                  <>Responsibilities</>
                </h5>

                <ul className="pl-2 my-3">{responsibilities_list}</ul>
              </div>

              <div className="px-5 mt-5 job-position-mobile-p">
                <h5>
                  <>Qualifications</>
                </h5>

                <ul className="pl-2 my-3">{qualifications_list}</ul>
              </div>
              <div className="px-5 mt-5 job-position-mobile-p">
                <h5>
                  <>Preferred but not essential</>
                </h5>

                <ul className="pl-2 my-3">{preferred_list}</ul>
              </div>
              <div className="px-5 mt-5 job-position-mobile-p">
                <h5>
                  <>Benefits</>
                </h5>
                <ul className="pl-2 my-3">{benefits_list}</ul>
              </div>
              <div className="px-5 mt-5 job-position-mobile-p">
                <h5>
                  <>How to Apply?</>
                </h5>

                <p>
                  Does this role sound like a good fit? Email us at
                  <a href="mailto:mailto:careers@anmol.network">
                    <strong> careers@anmol.network</strong>
                  </a>{" "}
                  or fill out this form
                  <a href="https://anmolnetwork.typeform.com/careers">
                    <strong> https://anmolnetwork.typeform.com/careers</strong>
                  </a>
                  <a href="https://anmolnetwork.typeform.com/careers">
                    <strong> https://anmolnetwork.typeform.com/careers</strong>
                  </a>
                </p>

                <ul className="pl-2 my-1">
                  <li className="mb-2">
                    Include the role's title in your subject line.
                  </li>
                  <li className="mb-2">
                    Send along links that best showcase the relevant things
                    you've built and done.
                  </li>
                </ul>
              </div>
              <div className="px-5 mt-5 job-position-mobile-p">
                <h5>
                  <>Hiring Process</>
                </h5>
                <p>
                  We are moving fast. If we like you, we’ll get back to you in
                  two weeks (maximum). We are quick to integrate newcomers into
                  our team.
                </p>
                <div className="my-4">
                  <button className="pill-btn" style={{ padding: "11px 27px" }}>
                    {" "}
                    Apply Now
                  </button>
                </div>
              </div>
            </Scrollbars>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
