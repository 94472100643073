import { useRef, useState } from "react";
import styled from "styled-components";
import PlayLogo from "../Assets/playlogo.svg";

export default function VideoPlayer({ src }) {
  const [played, setPlayed] = useState(false);

  const videoRef = useRef(null);

  const toggleMode = () => {
    if (played) {
      console.log(played);
      setPlayed(false);
      videoRef.current.pause();


    } else {
      console.log(played);
      setPlayed(true);
      videoRef.current.play();
    }
  };
  return (
    <VideoPlayerSec>
      <VideoCustom played={played} ref={videoRef} src={src} loop controls />
      <PlayPuase played={played} onClick={toggleMode} />

    </VideoPlayerSec>
  );
}

const VideoPlayerSec = styled.div`
  position: relative;
  display:table;
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
  }
`;

const VideoCustom = styled.video`
  -webkit-filter: blur( ${(props) => (props.played ? 0 :3)}px);
  -moz-filter: blur( ${(props) => (props.played ? 0 :3)}px);
  -o-filter: blur( ${(props) => (props.played ? 0 :3)}px);
  -ms-filter: blur( ${(props) => (props.played ? 0 :3)}px);
  filter: blur( ${(props) => (props.played ? 0 :3)}px);
  transition: filter 0.5s ease-in-out;

`;
const PlayPuase = styled.div`
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  margin:auto;
  width: 70%;
  height: 70%;
  background-image: ${(props) => (props.played ? null : `url(${PlayLogo})`)};
  background-color: transparent;
  background-position: center;
  background-size: 20%;
  background-repeat: no-repeat;
  opacity: ${(props) => (props.played ? 0 : 1)};
  transition: opacity 0.5s ease-in-out;
 
  
  
`;
