import moulds from "../src/Assets/BrowserIcons/Moulds.png";
import frac from "../src/Assets/BrowserIcons/Frac.png";
import collections from "../src/Assets/BrowserIcons/Collection.png";
import collage from "../src/Assets/BrowserIcons/collage.png";
import morphing from "./Assets/NFTMorphing/allNftMorph.png";
import dao from "../src/Assets/BrowserIcons/dao.png";
import token from "../src/Assets/BrowserIcons/token.png";
// members
import furqan from "../src/images/Furqan.png";
import phaedra from "../src/images/Phaedra.png";
import ilona from "../src/images/Ilona.png";
import taha from "../src/images/Taha.png";
import jordan from "../src/images/Jordan.png";

export const faqInfo = [
  {
    summary: "Who can use Moulds?",
    text: "Absolutely anybody! Moulds are designed for both businesses and individual users. They are the perfect tool for artists and creatives, but they can also be used for logistics purposes (such as minting receipts or tickets for events). ",
  },
  {
    summary: "Why did you guys make this feature?",
    text: "We noticed a disappointing trend within the industry. Newcomers are getting excited to craft their own NFTs, only to be turned off by the fact that making NFT collections (usually) requires some programming knowledge. Newcomers do not want to learn how to program simply to express themselves– they want to jump in and get involved! This is why we created our Moulds feature.",
  },
  {
    summary: "When is it going to be available?",
    text: "Our Moulds feature will be available very soon!",
  },
  {
    summary: "How can I benefit from using Moulds?\n",
    text: "The best way to benefit from Moulds is to onboard yourself into our network and begin creating a Mould. Moulds can be used to mint whole NFT sets, or you can lease Moulds out to others and earn a passive income!\n",
  },
  {
    summary: "What can I use this feature for?\n",
    text: "Its primary use is to create NFT sets with ease, but Moulds can also be used to create ticketing systems, receipts for online businesses, and other (more business-oriented) tasks. This is a highly versatile tool, so it is worth exploring it and seeing how inventive you can be with it!\n",
  },
  {
    summary: "How does this benefit the NFT space?\n",
    text: "The major benefit is that it helps onboard newcomers into the space, while making the industry less daunting and confusing. Our Moulds feature will help transform the NFT market into a more welcoming place. \n",
  },
  {
    summary:
      "How does Moulds level the playing field for artists looking to get into the space?\n",
    text: "The best aspect of our Moulds is that it requires no programming skills. Most artists do not have the time or energy to learn a new skill simply to express themselves and get their art out to the world. Moulds allow them to get entrenched in the space without having to learn any new skills. \n",
  },
  {
    summary: "What is the motivation behind creating this?\n",
    text: "The NFT market is exploding, yet from a technological perspective, it has arguably stagnated. Most NFT creators are already programmers or have had to work with programmers (meaning they must share profits). Our tool helps bring the industry forward by opening it up to more people in a friendlier manner.\n",
  },
  {
    summary: "How is Moulds different from what is already out there?\n",
    text: "There are a plethora of NFT minting tools out there, but the vast majority of them do not help you to generate NFT sets. Rather, they help you to merely turn a single file into an NFT. This is not helpful for people who want to build full non-fungible ecosystems. \n",
  },
  {
    summary: "Can anybody use Moulds?\n",
    text: "Yes! Not only does our tool require no programming knowledge, but it has a super simple and intuitive UX. Anybody who is even peripherally involved in the Web3 space will know good UX is a rarity and a luxury. \n",
  },
  {
    summary:
      "Are there any practical or real-world applications for this feature?\n",
    text: "Allowing creators to produce artwork is definitely a real-world application. Aesthetics and art are the most succinct and emotive ways in which humans express themselves, and without them, we would not be the multi-faceted beings that we are. However, Moulds can also be used as a logistics tool, allowing businesses to track and display their activity on-chain with ease.\n",
  },
  {
    summary: "Why is this feature a big deal?\n",
    text: "Not only does it allow artists to create NFTs without learning a programming language, but it has a simple and efficient UX. In an industry that is heavily dominated by programmers and developers, offering tools for people who are not technically savvy in that way is a huge part of onboarding the masses into the space. \n",
  },
  {
    summary: "What can NFTs made from Moulds do? Are they just normal NFTs?\n",
    text: "They are normal NFTs in the sense that they can do everything other NFTs can do. But they do have some additional abilities. For starters, NFTs minted from Moulds will be able to be Morphed, in the future. Morphing is a new feature we have planned in the future, which allows NFTs to be combined and merged to create new assets that share similar features to their counterparts. NFTs minted from Moulds will also be directly integrated into Anmol’s ecosystem, which will become increasingly important as we grow our network.\n",
  },
  {
    summary:
      "How can users monetize more using Moulds than just minting a normal NF on Open Sea or something?\n",
    text: "Moulds can be used to create a passive income. Users can lease their Moulds to others, earning money whenever somebody mints from them. Additionally, bear in mind that Anmol’s Moulds are a decentralized tool, rather than a centralized web2 project like OpenSea, meaning they embody the true nature of the industry better. \n",
  },
  {
    summary: "Can I make anything i want with Moulds?\n",
    text: "Right now, you can create image-based NFTs, and text-based NFTs. However, in the future, other mediums will be available, such as music and video NFTs. \n",
  },
  {
    summary: "When is this feature going to be ready?\n",
    text: "Our Moulds feature will be released very soon. We are nearly ready to open it to the world. \n",
  },
  {
    summary: "What is the inspiration behind the name?\n",
    text: "In the physical world, moulds are hollow containers that people fill up to create customized shapes of things. They are often used in cooking, pottery, glasswork, and most industrial factories use some type of mould. They are designed to streamline the creation process of something. Anmol’s Moulds are the same– they streamlining the NFT creation process. ",
  },
  {
    summary: "What is the best thing about the Moulds feature?\n",
    text: "Arguably, the best thing about this feature is its easy-to-understand UX. We have some highly dedicated, imaginative, and ingenious UX developers on our team who recognise how to present complex ideas via the most simple means. \n",
  },
  {
    summary:
      "How is Moulds going to shape or contribute to the future of blockchain?\n",
    text: "The biggest change Moulds can institage is that it will bring more artists and designers into the space who are daunted, confused, or scared of entering the web3 and NFT industry. Many of us take for granted just how steep the learning curve can be in the NFT world. We expect Moulds to lower that curve and let more people get invovled. \n",
  },
];

export const browserInfo = {
  moulds: {
    name: "Moulds",
    title: "Moulds: The Ultimate NFT Template Tool",
    text: {
      firstParagraph:
        "Do you want to create generative NFT art? Moulds have you covered! Make multiple NFTs from the same set of design layers and attributes, streamlining the creation of entire collections with similar features and aesthetics, where you control the scarcity and rarity of each feature yourself.\n",
      secondParagraph:
        "Whether you want to mint 10 or 10,000+ NFTs for your collection, our Mould Builder makes this easy, all without any coding knowledge needed. These are perfect for art projects and games, but also business solutions such as ticket sales, real-world goods and services, and logistics.",
    },
    video: "/mould.mp4#t=0.3",
  },
  nftAirdrop: {
    name: "NFT Airdrop",
    title: "NFT Airdrop",
    text: {
      firstParagraph:
        "Allow users to receive a customized AirDrop NFT, or one from a collection of tens of thousands with a few clicks of a button from a simple UI/UX and no coding. Users can receive an NFT with Coinbase Wallet, MetaMask, or Wallet Connect or they can enter their email or phone to create a cloud wallet.",
      secondParagraph:
        "Engage with your community by using NFT Airdrop to hold competitions and raffles. Users can check and verify if they are a winner and receive an airdrop.mp4 of their prize at the click of a button.  Our tool also lets you hold Gleam Campaigns where users need to perform tasks such as following or sharing on social media to earn their NFT.",
    },
    video: "/airdrop.mp4#t=0.1",
  },
  fractionalization: {
    name: "Fractionalization",
    title: "Fractionalization",
    text:
      "Users can send partial amounts of NFTs via our toolkit. This means people can separate and distribute" +
      " ownership of non-fungible goods, having them treated as shares in a company, or as a tool for managing group-buys within a community.",
    img: frac,
  },
  morphing: {
    name: "Morphing",
    title: "NFT Morphing",
    text: "Morphing is where two NFTs are combined to create an entirely new and unique one. Morphing gives creators the ability to expand their ideas in awe-inspiring ways. They are the perfect accompaniment to anybody’s imaginative work. When Morphing NFTs, you never truly know what will come out the other end!",
    img: morphing,
  },
  nftTicketing: {
    name: "NFT Ticketing",
    title: "NFT Ticketing",
    text: {
      firstParagraph:
        "NFT Tickets offer the same streamlined produvtion benefits of digital tickets but with" +
        " extended functionality, security, and additional opportunites for use as keepsake. NFT Tickets are" +
        " capable of assigning royalties all stakehodlers of the event, and if the tickets are resold the stakeholders continue to receive royalties.",
      secondParagraph:
        "NFT Tickets are also capable of interactivity such as changing color or design once a user attends an event, as well as offering opportunities for purchasers to acquire redeemables such as MP3s, Merchandise redemption, or additional event access upon entry to the event.",
    },
    img: "",
  },
  threeNft: {
    name: "3D NFTs and 3D NFT Moulds",
    title: "3D NFTs and 3D NFT Moulds ",
    text: {
      firstParagraph:
        "The three-dimensional NFTs are quickly becoming the most popular types of NFTs available." +
        "3D NFTs offer the same benefits as collectibles and gamification as traditional NFTs but with additional utility benefits . 3D NFTs can be incorporated in most metaverse-ready video games, as well as  used as digital profiles and avatars in virtual and augmented reality.",
      secondParagraph:
        "With Anmol Moulds, users can create 3D NFT Moulds from components, enbaling the creation of a collection tens, to tens of thousands or more 3D NFTs to be generated from the components.",
    },
    img: "",
  },
  collections: {
    name: "Collections",
    title: "Collections",
    text: "Collections bring several NFTs together, but instead of joining them (like stacks), they encase them together, with that encasing becoming an NFT. We are planning to market this as a logistics tool, or perhaps a tool for rounding together complete sets of NFTs and selling them all as one. \n",
    img: collections,
  },
  collages: {
    name: "Collages",
    title: "Collages",
    text: "A stack is where previously unrelated NFTs are locked together and combined into one singular NFT. We believe stacking will be a huge part of the NFT world, as it gives creators the ability to weave separate NFTs into one, where they all share each other’s attributes.\n",
    img: collage,
  },
  // social
  communityDao: {
    name: "Community DAO",
    title: "Community Based DAOs",
    text: {
      firstParagraph:
        "Create user-oriented, interactive spaces where people can come together and share ideas, resources, and experiences",
      secondParagraph:
        "ou can create your own communities governed independantly by DAOs. Having community specific financial structures, tokens, NFTs, and much more.Think of them as decentralized subreddits for the Web 3.0 era.",
      thirdParagraph:
        "We are empowering individuals to grow their own DAO-based communities within Anmol’s ecosystem, where users are incentivised to participate and maintain them.",
    },
    img: dao,
  },
  fanTokens: {
    name: "Fan Tokens",
    title: "Fan Tokens",
    text: "Mint your own Fan Token and give your community  access to a variety of fan-related membership perks like voting on club decisions, rewards, merchandise designs and unique experiences",
    img: "",
  },
  poap: {
    name: "POAP NFTs",
    title: "POAP NFTs",
    text: 'POAP is an acronym for "Proof of Attendance Protocol" . POAP NFTs is are a way to tokenize experiences,  shows you attended an event or experience, and keeo a record of life experiences. These NFT allow users to more effectively engage with audeinces by enabling customized experiences. POAP NFTs can offer integrated features such as private chsaat rooms, raffles, and badges which users can collect through participation and engagement in the real world, or in the metaverse. ',
    img: "",
  },
  passports: {
    name: "NFT Passports",
    title: "NFT Passports",
    text: "NFT Passports allow users to create NFTs that enable access to specific perks to one, or multiple organizations across Web3. Perks can include Educational Courses, Financial or Technical Mentorships, Discounts on blockchain platfroms, Access to communtiies, Entrance tickets to events, Presale access to NFTs and additional drops, and much more!",
    img: "",
  },
  // metaverse
  customWear: {
    name: "Custom Wearables",
    title: "Custom Wearables",
    text: "Easily craft one, or an entire collection of custom metaverse wearables.  Expand your real-world brand into the metaverse by crafting branded pieces of virtual clothing or digital accessories like dresses, shirts, jeans, sunglasses or bags that  can be usde to dress-up avatars in a virtual world.",
    img: "",
  },
  augmented: {
    name: "Augmented Reality NFTs",
    title: "Augmented Reality NFTs",
    text: "Create Augmented Reality NFTs with sound, visual, or other virtual effects. that are which are visually more realistic and more contextual pieces. Augmented Reality NFTs are an artwork which can be fully experienced anywhere and anytime via smart devices.",
    img: "",
  },
  avatar: {
    name: "Avatar Builder",
    title: "Avatar Builder",
    text: "Create a custom Avatar based upon rendered likenesses of a photo, or use componenents from Anmol’s Avatar Builder library. Create one, or an entire collecton of Avatars for personal engagement in the metaverse, or to represent your  digital or real world  community, team, or business.",
    img: "",
  },
  virtualPets: {
    name: "Virtual Pets",
    title: "Virtual Pets",
    text: "Create an NFT Virtual Pet that can “change”, or “grow” overtime via metadata based upon interaction with the owner. Virtual Pets playable NFTs that act as interest-generating piggy banks, created by staking DeFi tokens directly into the NFT.",
    img: "",
  },
  // defi
  defiCommerce: {
    name: "DeFi E-Commerec",
    title: "DeFi E-Commerece",
    text: "Create your blockchain eCommerece storefront in minutes with Anmol! Enhance revenue generation with blockchain eCommerce and gain the ability to process transactions for di    gital goods or NFTs with locked layers redeemable for real-world goods with secure crypto payments! The transparency and security of decentralized technology allow for more efficient transaction tracking. With Anmol's Mint Token, and White Label Wallet feature, customers can have a seamlessly branded user experience from arrival at the storefront through to checkout.",
    img: "",
  },
  mintToken: {
    name: "Mint Token",
    title: "Mint Token",
    text: "Create and manage your own tokens on any blockchain with custom vesting, burn schedules, and other functionalities from a simple UI/UX and no coding, integrating them within our ecosystem and blockchain.",
    video: "/mintToken.mp4#t=0.1",
  },
  whiteWallet: {
    name: "Whitelabel Wallet",
    title: "Whitelabel Wallet",
    text: "Create a branded ready-made (“turnkey”) solution for your customers, for storing and using various existing cryptocurrencies, as well as for creating your own cryptocurrency, fan tokens, and NFTs.",
    img: "",
  },
  // gameFI
  gameBuilder: {
    name: "Game Builder",
    title: "Game Builder",
    text: "Use Anmol’s Game Builder API to seamlessly connect game engines and core loops to Web3 and build your own custom Metaverse DApp.",
    img: "",
  },
  gameAssets: {
    name: "In-Game Assets",
    title: "In-Game Assets",
    text: "Deploy in-game assets such as  graphics (characters, environments, logos), background music, special effects and sound effects for popular DeFi  games, or games you have created with Anmol’s Game Builder.",
    img: "",
  },
};

export const membersInfo = [
  {
    name: "Furqan",
    tags: ["darwinia", "polkadot", "mark", "edgeware"],
    designation: "Founder & CVO",
    about:
      "Our Founder and Chief Vision Officer is Furqan Ahmed. Furqan has dedicated his working life towards blockchain technology, and increasing overall adoption. He is a well-versed Substrate developer and a highly experienced member of the Polkadot space. He has worked with Darwinia, Edgeware, Subscan, and Plasm. Furqan takes a hands-on approach to this project, oftentimes working on the programming side of Anmol.",
    img: furqan,
  },
  {
    name: "Phaedra",
    tags: ["tedx", "atnt", "bid", "covid-global"],
    designation: "Chief Marketing Officer",
    about:
      "Our Chief Marketing Officer is Phaedra Poliquin. Phaedra has a multifaceted background ranging from the entertainment industry, corporate executive roles, and government campaigns. She has produced TEDx events, and was an organizer of the COVID 19 Global Hackathon. She previously worked on the DLN blockchain project.",
    img: phaedra,
  },
  {
    name: "Ilona",
    tags: "",
    designation: "Illustrator",
    about:
      "Our Illustrator is Ilona Korzewska. Ilona is well-versed in design, previously being a Visual Designer at Picadilla Games, and a Graphic Designer at The Knights of Unity. She has also been a Youth Coordinator at Mouvement international ATD Quart Monde, a global human rights project.",
    img: ilona,
  },
  {
    name: "Taha",
    tags: "",
    designation: "Design Lead",
    about:
      "Our Design Lead is Taha Yahya Baig. He is a Graphic Designer and a UI/UX Researcher and Designer, with 3 years experience in the space. Taha has a Degree in Computer Science from The National University of Computer and Emerging Sciences. He was also a Managing Partner at ByteCorp, a machine learning and computer vision company. He holds a certificate in The Fundamentals of Digital Marketing, issued by Google Digital Garage, and has completed a specialization in User Experience Research and Design.",
    img: taha,
  },
  {
    name: "Jordan",
    tags: ["nervos"],
    designation: "Advisor",
    about:
      "Our advisor is a Senior Software Engineer at the Nervos Foundation. He is the founder of the RigidBit storage blockchain and has written three blockchain related whitepapers. His blockchain and tech related articles have been featured in multiple publications. He has been the lead developer of Brilliant Notion since 2013 and has been involved with cryptocurrency since 2011.",
    img: jordan,
  },
];
